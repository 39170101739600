import { gql, useMutation } from '@apollo/client'
import type { imageMetaCustomerCreate } from '../../../__generated__/globalTypes'
import { apolloClient } from '../../helper/graphql'
import type { putProfileImageQuery, putProfileImageQueryVariables } from './__generated__/putProfileImageQuery'

const PUT_PROFILE_IMAGE = gql`
  mutation putProfileImageQuery(
    $token: String!
    $fileMeta: imageMetaCustomerCreate!
    $oldImageId: Int
  ) {
    profileImagesCustomer(userAccessToken: $token, fileMetas: [$fileMeta]) {
      success
    }
    deleteAssets(assetIds: [$oldImageId]) {
      success
    }
  }
`

export function putProfileImage (token: string, fileMeta: imageMetaCustomerCreate, oldImageId: number) {
  return apolloClient.query<putProfileImageQuery, putProfileImageQueryVariables>({
    query: PUT_PROFILE_IMAGE,
    variables: {
      token,
      fileMeta,
      oldImageId,
    },
  })
}

export function usePutProfileImage () {
  return useMutation<putProfileImageQuery, putProfileImageQueryVariables>(PUT_PROFILE_IMAGE)
}
