import { gql, useMutation } from '@apollo/client'
import { apolloClient } from '../../helper/graphql'
import type { reportPostQuery, reportPostQueryVariables } from './__generated__/reportPostQuery'

const PUT_REPORT_POST = gql`
  mutation reportPostQuery ($postId: String!) {
    reportPost(postId: $postId) {
      success
    }
  }
`

export function reportPost (postId: string) {
  return apolloClient.query<reportPostQuery, reportPostQueryVariables>({
    query: PUT_REPORT_POST,
    variables: {
      postId,
    },
  })
}

export function useReportPost () {
  return useMutation<reportPostQuery, reportPostQueryVariables>(PUT_REPORT_POST)
}
