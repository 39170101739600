import { gql } from '@apollo/client'
import { Button, HStack, Spacer } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useUpdateComment } from '../../../gql/mutations/putUpdateCommentQuery'
import type { getCommentsQuery_getTribelloCommentListing_edges_node } from '../../../gql/queries/__generated__/getCommentsQuery'
import { getAuthToken } from '../../../helper/auth'
import MentionInput from '../../MentionInput'

interface IProps {
  comment: getCommentsQuery_getTribelloCommentListing_edges_node
  setEditing(value: boolean): void
}
const CommentContentEditing: React.FC<IProps> = ({ comment, setEditing }) => {
  const [content, setContent] = useState(comment.content ?? '')

  const [updateComment, { loading }] = useUpdateComment({
    update (cache, { data }) {
      if (data?.updateComment?.success) {
        cache.writeFragment({
          id: `${comment.__typename}:${comment.id ?? 0}`,
          data: {
            id: comment.id,
            content: content,
          },
          fragment: gql`
            fragment CommentFragment on ${comment.__typename} {
              id
              content
            }
          `,
        })
      }
    },
  })

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    await updateComment({
      variables: {
        commentID: Number(comment.id ?? 0),
        content: content,
        userAccessToken: getAuthToken(),
      },
    })
    setEditing(false)
  }

  return (
    <form onSubmit={handleSubmit}>
      <MentionInput
        onChange={(e) => setContent(e.target.value)}
        value={content}
        onFocus={e => e.target.setSelectionRange(e.target.value.length, e.target.value.length)}
      />
      <Spacer mb={3}/>
      <HStack spacing={4} justifyContent="flex-end">
        <Button onClick={() => setEditing(false)}>Abbrechen</Button>
        <Button colorScheme="primary" type="submit" disabled={loading || content.length === 0}>Speichern</Button>
      </HStack>
    </form>
  )
}

export default CommentContentEditing
