import { CheckCircleIcon } from '@chakra-ui/icons'
import { Box, Flex, Heading, Input, List, ListItem, Popover, PopoverAnchor, PopoverBody, PopoverContent, Spinner, Tag, TagLabel, TagRightIcon, useColorModeValue, VStack } from '@chakra-ui/react'
import { useCombobox } from 'downshift'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { putTag } from '../../gql/mutations/putTag'
import { getTagsById, useTagsByName, useTagsByTagType } from '../../gql/queries/getTags'
import type { getTribelloTagTagsByType_tagsByTagType_edges_tags as ITag } from '../../gql/queries/__generated__/getTribelloTagTagsByType'
import { calculateTagColor } from '../../helper/tags'

const TagSelect: React.FC<{ defaultTags?: number[], tags: number[], setTags: (value: React.SetStateAction<number[]>) => void, enableTagCreation?: boolean}> = ({ defaultTags, tags, setTags, enableTagCreation = false }) => {
  const { loading: tagsByTypeLoading, data: tagsByType } = useTagsByTagType({ showHidden: false, type: '' })
  const [getTagsByName, { loading: tagsByNameLoading, data: tagsByNameData }] = useTagsByName()
  const tagsByName = useMemo(() => tagsByNameData ? tagsByNameData.getTribelloTagListing?.edges : [], [tagsByNameData])
  const [tagsArray, setTagsArray] = useState<ITag[]>([])
  const [selectedTags, setSelectedTags] = useState<ITag[]>([])
  const selectedColor = useColorModeValue('blackAlpha.100', 'whiteAlpha.100')
  const inputRef = useRef<HTMLInputElement>(null)
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()

  const handleSelectTag = (tag?: ITag) => {
    if (!tag) return

    setSelectedTags(prevTags => {
      let newTags = []

      if (prevTags.some(prevTag => prevTag.id === tag.id)) {
        newTags = prevTags.filter(prevTag => prevTag.id !== tag.id)
      } else {
        newTags = [...prevTags, tag]
      }

      return newTags
    })
  }

  const createNewTag = async (name: string) => {
    if (!name) return

    try {
      const { data } = await putTag(name)
      if (data.createTribelloTag?.output) {
        handleSelectTag(data.createTribelloTag.output as ITag)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const {
    isOpen,
    getComboboxProps,
    getInputProps,
    getMenuProps,
    getItemProps,
    setInputValue,
    highlightedIndex,
    inputValue,
  } = useCombobox({
    onInputValueChange ({ inputValue }) {
      if (!inputValue) return
      clearTimeout(timeoutRef.current)
      timeoutRef.current = setTimeout(() => {
        getTagsByName({ variables: { filter: `{"name": {"$like": "%${inputValue}%"}}` } })
      }, 300)
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (!selectedItem) return

      if (selectedItem.createNew) {
        createNewTag(selectedItem.name ?? '')
      } else {
        handleSelectTag(selectedItem)
      }
      setInputValue('')
    },
    items: tagsArray,
    itemToString (item) {
      return item ? item.name ?? '' : ''
    },
    defaultHighlightedIndex: 0,
  })

  // set init value
  useEffect(() => {
    const init = async () => {
      const { data } = await getTagsById(defaultTags?.join(',') ?? tags.join(','))

      setSelectedTags(data.getTribelloTagListing?.edges?.map(tag => tag?.node as ITag) ?? [])
    }

    if (defaultTags?.length) {
      init()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // set tags value
  useEffect(() => {
    setTags(selectedTags.map(tag => Number(tag.id)))
  }, [selectedTags, setTags])

  // set tagsArray depend on enableTagCreation
  useEffect(() => {
    if (!tagsByName?.length && enableTagCreation) {
      setTagsArray([{ __typename: 'object_TribelloTag', id: 'new_tag_item', name: inputValue, tagType: null, createNew: true }])
    } else {
      setTagsArray(tagsByName?.map(tag => tag?.node as ITag) ?? [])
    }
  }, [tagsByName, enableTagCreation, inputValue])

  return (
    <VStack align="flex-start" spacing={4}>
      <Box>
        <Heading mb={2}>Ausgewählte Tags</Heading>
        {selectedTags.length > 0
          ? (
            <Flex w="full" overflowX="auto" gap={2} flexWrap="wrap">
              {selectedTags.map(tag => (
                <Tag
                  cursor="pointer"
                  size="lg"
                  borderRadius="full"
                  onClick={() => handleSelectTag(tag)}
                  colorScheme={calculateTagColor(tag.tagType?.name ?? '')}
                  key={tag.id}
                  display="flex"
                  justifyContent="space-between"
                >
                  <TagLabel>{tag.name}</TagLabel>
                  {selectedTags.some(searchTag => searchTag.id === tag.id) && <TagRightIcon><CheckCircleIcon/></TagRightIcon>}
                </Tag>
              ))}
            </Flex>
            )
          : (
            <Box>Keine Tags ausgewählt</Box>
            )}
      </Box>
      <Box w="full">
        <Heading mb={2}>Suchen</Heading>
        <Box w="full" {...getComboboxProps()}>
          <Popover
            isOpen={Boolean(isOpen && inputValue)}
            autoFocus={false}
            matchWidth
          >
            <PopoverAnchor>
              <Input
                w="full"
                {...getInputProps({
                  ref: inputRef,
                })}
              />
            </PopoverAnchor>
            <PopoverContent width={inputRef.current?.offsetWidth ?? 'auto'}>
              <PopoverBody px={0} py={2} bgColor="secondary.500" overflow="hidden">
                <List {...getMenuProps()} maxH="60vh" overflowY="auto">
                  {tagsByNameLoading
                    ? (
                      <ListItem
                        px={4}
                        py={2}
                      >
                        <Spinner/>
                      </ListItem>
                      )
                    : tagsArray.length === 0
                      ? (
                        <ListItem
                          px={4}
                          py={2}
                        >
                          Nichts gefunden
                        </ListItem>
                        )
                      : (
                          tagsArray.map((item, index) => (
                            <ListItem
                              key={`TAG_${index}`}
                              {...getItemProps({ item, index })}
                              px={4}
                              py={2}
                              cursor="pointer"
                              bg={index === highlightedIndex && selectedColor}
                            >
                              {item.name} {item.createNew && 'hinzufügen'}
                            </ListItem>
                          ))
                        )}
                </List>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Box>
      </Box>
      {tagsByTypeLoading
        ? <Box> Loading...</Box>
        : (
          <Box>
            {tagsByType?.tagsByTagType?.edges?.map((tagsGroup, tagsGroupIndex) => {
              if (!tagsGroup?.tagType) return null

              return (
                <Box key={`TAG_GROUP_${tagsGroup.tagType.id ?? tagsGroupIndex}`} _notLast={{ mb: 4 }}>
                  <Heading mb={2}>{tagsGroup.tagType.name}</Heading>
                  <Flex gap={2} flexWrap="wrap">
                    {tagsGroup.tags?.map((tag, tagIndex) => {
                      if (!tag) return null

                      return (
                        <Tag
                          key={`TAG_${tag.id ?? tagIndex}`}
                          cursor="pointer"
                          size="lg"
                          borderRadius="full"
                          onClick={() => handleSelectTag(tag)}
                          colorScheme={calculateTagColor(tag.tagType?.name ?? '')}
                          display="flex"
                          justifyContent="space-between"
                        >
                          <TagLabel>{tag.name}</TagLabel>
                          {selectedTags.some(selectedTag => selectedTag.id === tag.id) && <TagRightIcon><CheckCircleIcon/></TagRightIcon>}
                        </Tag>
                      )
                    })}
                  </Flex>
                </Box>
              )
            })}
          </Box>
          )}
    </VStack>
  )
}
export default TagSelect
