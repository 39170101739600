import { gql, useQuery } from '@apollo/client'
import type { getHotelQuery, getHotelQueryVariables } from './__generated__/getHotelQuery'

// TODO: Use @include(isDetail) for fields that are only required on detail view, to keep requests in feed smaller
export const HOTEL_FRAGMENT = gql`
fragment HotelFragment on object_TribelloHotel {
  id
  slug
  name
  description
  stars
  board
  likesCount
  geoPoint{
    latitude
    longitude
  }
  images {
    ... on asset {
      fullpath(thumbnail:"hotel")
      mimetype
    }
  }
  equipment {
    text
  }
  highlights {
    text
  }
  websiteTarget
  website {
    text
    path
  }
  tags {
    ...on object_TribelloTag {
      name
      id
      tagType {
        ...on object_TribelloTagType {
          name
          description
          id
        }
      }
    }
  }
  region
  country {
    isoCode
  }
}
`

export const GET_HOTEL_BY_ID = gql`
  query getHotelQuery ($id: Int!) {
    getTribelloHotel (id: $id) {
      ...HotelFragment
    }
  }

  ${HOTEL_FRAGMENT}
`

export const GET_HOTEL_BY_SLUG = gql`
  query getHotelQuery ($slug: String!) {
    getBySlug (slug: $slug, type: "hotel") {
      element {
        ...HotelFragment
      }
    }
  }

  ${HOTEL_FRAGMENT}
`

export function useHotel (id: number | string) {
  return useQuery<getHotelQuery, getHotelQueryVariables>(typeof id === 'number' ? GET_HOTEL_BY_ID : GET_HOTEL_BY_SLUG, {
    variables: {
      id: typeof id === 'number' ? id : undefined,
      slug: typeof id === 'string' ? id : undefined,
    },
  })
}
