import { ApolloProvider } from '@apollo/client'
import { ChakraProvider, Portal } from '@chakra-ui/react'
import { IonApp } from '@ionic/react'
import '@ionic/react/css/core.css'
import '@ionic/react/css/display.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/padding.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/typography.css'
import '@uppy/core/dist/style.css'
import '@uppy/file-input/dist/style.css'
// import 'mapbox-gl/dist/mapbox-gl.css'
import React from 'react'
import { UserProvider } from './helper/auth'
import { FilterDisclosureProvider } from './helper/filterDisclosure'
import { apolloClient } from './helper/graphql'
import { NotificationsProvider } from './helper/notifications'
import { UploadProvider } from './helper/upload'
import { Router } from './router/Router'
import { chakraTheme, Variables } from './theme'

const App: React.FC = () => (
  <ApolloProvider client={apolloClient}>
    <ChakraProvider theme={chakraTheme}>
      <UserProvider>
        <NotificationsProvider>
          <UploadProvider>
            <FilterDisclosureProvider>
              <Variables/>
              <IonApp>
                <Router/>
                <Portal>
                  <script id="CookieDeclaration" src="https://consent.cookiebot.com/e57bcd3a-b9fa-46f9-a5eb-cd6564570927/cd.js" type="text/javascript" async/>
                </Portal>
              </IonApp>
            </FilterDisclosureProvider>
          </UploadProvider>
        </NotificationsProvider>
      </UserProvider>
    </ChakraProvider>
  </ApolloProvider>
)

export default App
