import { Box, useToast } from '@chakra-ui/react'
import { IonButton, IonButtons, IonIcon, IonItem, IonList, IonPopover } from '@ionic/react'
import { create, ellipsisVertical, flag, trash } from 'ionicons/icons'
import React from 'react'
import type { getCommentsQuery_getTribelloCommentListing_edges_node } from '../../../gql/queries/__generated__/getCommentsQuery'
import { useUser } from '../../../helper/auth'
import type { IPopOverState } from '../../../types/IPopOverState'

interface IProps {
  popoverState: IPopOverState
  deleteComment: () => void
  comment: getCommentsQuery_getTribelloCommentListing_edges_node
  setShowPopover: (state: IPopOverState) => void
  setEdit: (edit: boolean) => void
}

const CommentPopover: React.FC<IProps> = ({ comment, popoverState, setShowPopover, setEdit, deleteComment }) => {
  const user = useUser()
  const toast = useToast()
  return (
    <>
      <IonPopover
        mode="md"
        event={popoverState.event}
        isOpen={popoverState.showPopover}
        onDidDismiss={() => setShowPopover({ showPopover: false })}
      >
        <IonList>
          {user.isLoggedIn && (Number(comment.user?.id) === Number(user.data?.user?.id)) && (
            <>
              <IonItem
                button
                onClick={() => {
                  setShowPopover({ showPopover: false })
                  setEdit(true)
                }}
              >
                <IonIcon icon={create}/>
                <Box pl=".5em">
                  Editieren
                </Box>
              </IonItem>
              <IonItem
                button
                onClick={deleteComment}
              >
                <IonIcon icon={trash}/>
                <Box pl=".5em">
                  Löschen
                </Box>
              </IonItem>
            </>
          )}
          <IonItem button lines="none" onClick={() => toast({ status: 'info', title: 'Prototyp', description: 'Diese Funktion steht noch nicht zur Verfügung.' })}>
            <IonIcon icon={flag}/>
            <Box pl=".5em">
              Melden
            </Box>
          </IonItem>
        </IonList>
      </IonPopover>
      <IonButtons>
        <IonButton
          mode="md"
          shape="round"
          slot="icon-only"
          onClick={(e) => {
            setShowPopover({ showPopover: true, event: e.nativeEvent })
          }}
        >
          <IonIcon icon={ellipsisVertical}/>
        </IonButton>
      </IonButtons>
    </>
  )
}

export default CommentPopover
