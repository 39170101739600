import type { MutationHookOptions } from '@apollo/client'
import { gql, useMutation } from '@apollo/client'
import { apolloClient } from '../../helper/graphql'
import type { putUpdateCommentQuery, putUpdateCommentQueryVariables } from './__generated__/putUpdateCommentQuery'

const PUT_UPDATE_COMMENT = gql`
mutation putUpdateCommentQuery($commentID: Int!, $content: String!, $userAccessToken: String!) {
  updateComment(commentId: $commentID, content: $content, userAccessToken: $userAccessToken) {
    success
  }
}`

export function updateComment (commentId: string | number, content: string, userAccessToken: string) {
  return apolloClient.query<putUpdateCommentQuery, putUpdateCommentQueryVariables>({
    query: PUT_UPDATE_COMMENT,
    variables: {
      commentID: Number(commentId),
      content,
      userAccessToken,
    },
  })
}

export function useUpdateComment (options?: MutationHookOptions<putUpdateCommentQuery, putUpdateCommentQueryVariables>) {
  return useMutation<putUpdateCommentQuery, putUpdateCommentQueryVariables>(PUT_UPDATE_COMMENT, options)
}
