import { gql, useLazyQuery, useQuery } from '@apollo/client'
import { apolloClient } from '../../helper/graphql'
import type { getTribelloTagsByIdQuery, getTribelloTagsByIdQueryVariables, getTribelloTagsByNameQuery, getTribelloTagsByNameQueryVariables } from './__generated__/getTribelloTagsByIdQuery'
import type { getTribelloTagTagsByType, getTribelloTagTagsByTypeVariables } from './__generated__/getTribelloTagTagsByType'

// TODO: Not automatically generated, needs to be updated manually
export type ITagType = 'region' | 'interest' | 'media' | 'category' | 'type' | 'other' | ''

const TAG_TYPE_FRAGMENT = gql`
fragment TagTypeFragment on object_TribelloTagType {
  id
  hidden
  description
  name
}
`

const TAG_FRAGMENT = gql`
fragment TagFragment on object_TribelloTag{
  id
  name
  tagType {
   ...TagTypeFragment
  }
}
${TAG_TYPE_FRAGMENT}
`

const GET_TAGS_BY_TYPE = gql`
query getTribelloTagTagsByType ($showHidden: Boolean!, $type: String) {
  tagsByTagType(type: $type, showHidden: $showHidden) {
    edges {
      tagType {
        name
      }
      tags {
        ...TagFragment
      }
    }
  }
}
${TAG_FRAGMENT}
`
const GET_TAGS_BY_ID = gql`
query getTribelloTagsByIdQuery($ids: String!) {
  getTribelloTagListing(ids: $ids) {
    edges {
      node {
        ...TagFragment
      }
    }
  }
}
${TAG_FRAGMENT}
`

const GET_TAGS_BY_NAME = gql`
query getTribelloTagsByName($filter: String!) {
  getTribelloTagListing(filter: $filter) {
    totalCount
    edges {
      node {
        ...TagFragment
      }
    }
  }
}
${TAG_FRAGMENT}
`

export async function getTagsById (ids: string) {
  return apolloClient.query<getTribelloTagsByIdQuery, getTribelloTagsByIdQueryVariables>({
    query: GET_TAGS_BY_ID,
    variables: { ids },
  })
}

export function useTagsByTagType (options: getTribelloTagTagsByTypeVariables) {
  return useQuery<getTribelloTagTagsByType, getTribelloTagTagsByTypeVariables>(GET_TAGS_BY_TYPE, { variables: options })
}

export function useTagsById (ids: string) {
  return useQuery<getTribelloTagsByIdQuery, getTribelloTagsByIdQueryVariables>(GET_TAGS_BY_ID, { variables: { ids } })
}

export function useTagsByName (filter?: string) {
  return useLazyQuery<getTribelloTagsByNameQuery, getTribelloTagsByNameQueryVariables>(GET_TAGS_BY_NAME, { variables: { filter: filter ?? '' }, fetchPolicy: 'network-only' })
}
