import { gql } from '@apollo/client'
import { apolloClient } from '../../helper/graphql'
import type { putCreateBookmarkQuery, putCreateBookmarkQueryVariables } from './__generated__/putCreateBookmarkQuery'
import type { putCreateHotelBookmarkQuery, putCreateHotelBookmarkQueryVariables } from './__generated__/putCreateHotelBookmarkQuery'
import type { putCreateInspirationBookmarkQuery, putCreateInspirationBookmarkQueryVariables } from './__generated__/putCreateInspirationBookmarkQuery'
import type { putRemoveBookmarkQuery, putRemoveBookmarkQueryVariables } from './__generated__/putRemoveBookmarkQuery'
import type { putRemoveHotelBookmarkQuery, putRemoveHotelBookmarkQueryVariables } from './__generated__/putRemoveHotelBookmarkQuery'
import type { putRemoveInspirationBookmarkQuery, putRemoveInspirationBookmarkQueryVariables } from './__generated__/putRemoveInspirationBookmarkQuery'

const PUT_CREATE_POST_BOOKMARK = gql`
mutation putCreatePostBookmarkQuery($postId: Int!, $userAccessToken: String!) {
  createBookmark (postId: $postId, userAccessToken: $userAccessToken){
    success
  }
}`

const PUT_REMOVE_POST_BOOKMARK = gql`
mutation putRemovePostBookmarkQuery($postId: Int!, $userAccessToken: String!) {
  deleteBookmark(postId: $postId, userAccessToken: $userAccessToken){
    success
  }
}`

const PUT_CREATE_HOTEL_BOOKMARK = gql`
mutation putCreateHotelBookmarkQuery($hotelId: Int!, $userAccessToken: String!) {
  createBookmark (hotelId: $hotelId, userAccessToken: $userAccessToken){
    success
  }
}`

const PUT_REMOVE_HOTEL_BOOKMARK = gql`
mutation putRemoveHotelBookmarkQuery($hotelId: Int!, $userAccessToken: String!) {
  deleteBookmark(hotelId: $hotelId, userAccessToken: $userAccessToken){
    success
  }
}`

const PUT_CREATE_INSPIRATION_BOOKMARK = gql`
mutation putCreateInspirationBookmarkQuery($inspirationId: Int!, $userAccessToken: String!) {
  createBookmark (inspirationId: $inspirationId, userAccessToken: $userAccessToken){
    success
  }
}`

const PUT_REMOVE_INSPIRATION_BOOKMARK = gql`
mutation putRemoveInspirationBookmarkQuery($inspirationId: Int!, $userAccessToken: String!) {
  deleteBookmark(inspirationId: $inspirationId, userAccessToken: $userAccessToken){
    success
  }
}`

export function createPostBookmark (postId: string | number, userAccessToken: string) {
  return apolloClient.query<putCreateBookmarkQuery, putCreateBookmarkQueryVariables>({
    query: PUT_CREATE_POST_BOOKMARK,
    variables: {
      postId: Number(postId),
      userAccessToken,
    },
  })
}

export function removePostBookmark (postId: string | number, userAccessToken: string) {
  return apolloClient.query<putRemoveBookmarkQuery, putRemoveBookmarkQueryVariables>({
    query: PUT_REMOVE_POST_BOOKMARK,
    variables: {
      postId: Number(postId),
      userAccessToken,
    },
  })
}

export function createHotelBookmark (hotelId: string | number, userAccessToken: string) {
  return apolloClient.query<putCreateHotelBookmarkQuery, putCreateHotelBookmarkQueryVariables>({
    query: PUT_CREATE_HOTEL_BOOKMARK,
    variables: {
      hotelId: Number(hotelId),
      userAccessToken,
    },
  })
}

export function removeHotelBookmark (hotelId: string | number, userAccessToken: string) {
  return apolloClient.query<putRemoveHotelBookmarkQuery, putRemoveHotelBookmarkQueryVariables>({
    query: PUT_REMOVE_HOTEL_BOOKMARK,
    variables: {
      hotelId: Number(hotelId),
      userAccessToken,
    },
  })
}

export function createInspirationBookmark (inspirationId: string | number, userAccessToken: string) {
  return apolloClient.query<putCreateInspirationBookmarkQuery, putCreateInspirationBookmarkQueryVariables>({
    query: PUT_CREATE_INSPIRATION_BOOKMARK,
    variables: {
      inspirationId: Number(inspirationId),
      userAccessToken,
    },
  })
}

export function removeInspirationBookmark (inspirationId: string | number, userAccessToken: string) {
  return apolloClient.query<putRemoveInspirationBookmarkQuery, putRemoveInspirationBookmarkQueryVariables>({
    query: PUT_REMOVE_INSPIRATION_BOOKMARK,
    variables: {
      inspirationId: Number(inspirationId),
      userAccessToken,
    },
  })
}
