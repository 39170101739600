import { Box } from '@chakra-ui/react'
import React from 'react'

const AdBox: React.FC = () => {
  const iframeNumber = Math.floor(Math.random() * 1000) + 100

  return (
    <Box width="100%">
      <iframe width="100%" height="400px" id="profile_page" name="profile_page" src={`https://ads.tribello.dog/www/delivery/afr.php?zoneid=1&cb=${iframeNumber}`} frameBorder="0" scrolling="no" allow="autoplay">
        <a href={`https://ads.tribello.dog/www/delivery/ck.php?n=a9cdfdf7&;cb=${iframeNumber}`} target="_blank" rel="noreferrer">
          <img src={`https://ads.tribello.dog/www/delivery/avw.php?zoneid=1&;cb=${iframeNumber}&;n=a9cdfdf7`} alt=""/>
        </a>
      </iframe>
    </Box>
  )
}

export default React.memo(AdBox)
