import { Flex } from '@chakra-ui/layout'
import { Box, Icon } from '@chakra-ui/react'
import React from 'react'
import { MdChatBubbleOutline } from 'react-icons/md'
import { Link as RouterLink } from 'react-router-dom'
import { MotionBox } from '../../Motion'

interface CommentProps {
  commentCount: number
  postId: string | number
  onClick?: () => void
}

export const Comment: React.FC<CommentProps> = ({ commentCount, postId, onClick }) => {
  return (
    <Box as={RouterLink} to={`/posts/${postId}`}>
      <Flex
        justifyContent="center"
        alignItems="center"
        onClick={onClick}
        cursor="pointer"
        userSelect="none"
        transition="color .35s"
        _hover={{ color: 'blue.400' }}
      >
        <MotionBox whileTap={{ scale: 0.8 }}>
          <Icon w="32px" h="32px" as={MdChatBubbleOutline}/>
        </MotionBox>
        <Box as="span" px=".5em">{commentCount}</Box>
      </Flex>
    </Box>
  )
}
