import { gql } from '@apollo/client'
import { apolloClient } from '../../helper/graphql'
import type { putTribelloTagQuery, putTribelloTagQueryVariables } from './__generated__/putTribelloTagQuery'

// tagtype hardcoded because it's always "UserGenerated"
export const PUT_TRIBELLO_TAG = gql`
mutation putTribelloTagQuery($name: String!) {
  createTribelloTag(
    key: $name, 
    input:{
      name: $name
      tagType: {id: 2693, type:"object"}
    }, parentId: 2717 ) {
    success
    message
    output {
      id
      name
      tagType {
        ...on object_TribelloTagType {
          name
          description
          id
        }
      }
    }
  }
}
`
export function putTag (name: string) {
  return apolloClient.query<putTribelloTagQuery, putTribelloTagQueryVariables>({
    query: PUT_TRIBELLO_TAG,
    variables: {
      name,
    },
  })
}
