import { gql, useQuery } from '@apollo/client'
import { apolloClient } from '../../helper/graphql'
import type { getIsCommentLikedQuery, getIsCommentLikedQueryVariables } from './__generated__/getIsCommentLikedQuery'
import type { getIsHotelLikedQuery, getIsHotelLikedQueryVariables } from './__generated__/getIsHotelLikedQuery'
import type { getIsInspirationLikedQuery, getIsInspirationLikedQueryVariables } from './__generated__/getIsInspirationLikedQuery'
import type { getIsPostLikedQuery, getIsPostLikedQueryVariables } from './__generated__/getIsPostLikedQuery'

export const GET_IS_POST_LIKED = gql`
query getIsPostLikedQuery($postID: Int!, $token: String!) {
  likedByUser (postId: $postID, userAccessToken: $token) {
    liked
  }
}
`

export const GET_IS_COMMENT_LIKED = gql`
query getIsCommentLikedQuery($commentID: Int!, $token: String!) {
  likedByUser (commentId: $commentID, userAccessToken: $token) {
    liked
  }
}`

export const GET_IS_HOTEL_LIKED = gql`
query getIsHotelLikedQuery($hotelID: Int!, $token: String!) {
  likedByUser (hotelId: $hotelID, userAccessToken: $token) {
    liked
  }
}
`

export const GET_IS_INSPIRATION_LIKED = gql`
query getIsInspirationLikedQuery($inspirationId: Int!, $token: String!) {
  likedByUser (inspirationId: $inspirationId, userAccessToken: $token) {
    liked
  }
}
`
/**
 * @deprecated Use `getIsLiked()` instead.
 * @param postID The post ID.
 * @param token The user access token.
 * @returns Returns if the post is liked by the user
 */
export async function getIsPostLiked (postID: number | string, token: string) {
  return apolloClient.query<getIsPostLikedQuery, getIsPostLikedQueryVariables>({
    query: GET_IS_POST_LIKED,
    variables: {
      postID: Number(postID),
      token,
    },
  })
}

// TODO: Fix id type
export function useIsLiked (token: string, type: 'post' | 'comment' | 'hotel' | 'inspiration', id?: number | string) {
  switch (type) {
    case 'post':
    // useQuery is not used here, but returned
    // eslint-disable-next-line
    return useQuery<getIsPostLikedQuery, getIsPostLikedQueryVariables>(GET_IS_POST_LIKED, {
        variables: {
          postID: Number(id),
          token,
        },
      })
    case 'comment':
    // useQuery is not used here, but returned
    // eslint-disable-next-line
    return useQuery<getIsCommentLikedQuery, getIsCommentLikedQueryVariables>(GET_IS_COMMENT_LIKED, {
        variables: {
          commentID: Number(id),
          token,
        },
      })
    case 'hotel':
    // useQuery is not used here, but returned
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useQuery<getIsHotelLikedQuery, getIsHotelLikedQueryVariables>(GET_IS_HOTEL_LIKED, {
        variables: {
          hotelID: Number(id),
          token,
        },
      })
    case 'inspiration':
    // useQuery is not used here, but returned
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useQuery<getIsInspirationLikedQuery, getIsInspirationLikedQueryVariables>(GET_IS_INSPIRATION_LIKED, {
        variables: {
          inspirationId: Number(id),
          token,
        },
      })
  }
}

export async function getIsLiked (token: string, type: 'post' | 'comment' | 'hotel' | 'inspiration', id?: number | string) {
  switch (type) {
    case 'post':
      return apolloClient.query<getIsPostLikedQuery, getIsPostLikedQueryVariables>({
        query: GET_IS_POST_LIKED,
        variables: {
          postID: Number(id),
          token,
        },
      })
    case 'comment':
      return apolloClient.query<getIsCommentLikedQuery, getIsCommentLikedQueryVariables>({
        query: GET_IS_COMMENT_LIKED,
        variables: {
          commentID: Number(id),
          token,
        },
      })
    case 'hotel':
      return apolloClient.query<getIsHotelLikedQuery, getIsHotelLikedQueryVariables>({
        query: GET_IS_HOTEL_LIKED,
        variables: {
          hotelID: Number(id),
          token,
        },
      })
    case 'inspiration':
      return apolloClient.query<getIsInspirationLikedQuery, getIsInspirationLikedQueryVariables>({
        query: GET_IS_INSPIRATION_LIKED,
        variables: {
          inspirationId: Number(id),
          token,
        },
      })
  }
}
