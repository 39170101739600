export interface IFilter {
  first?: number
  after?: number
  tags?: string
  searchTerm?: string
  byUser?: string
  sort?: string
  sortDirection?: 'ASC' | 'DESC'
  tagCondition?: 'AND' | 'OR'
  type?: 'post' | 'hotel'
}

const parseTags = (tags?: string) => {
  if (tags) {
    return (JSON.parse(tags) as string[]).map(tag => parseInt(tag))
  }
}

const filterBuilderMixed = (filter: IFilter) => {
  return {
    orderKey: filter.sort,
    offset: filter.after,
    perPage: filter.first ?? 10,
    tags: parseTags(filter.tags),
    tagCondition: filter.tagCondition ?? 'OR',
    filter: filter.type,
  }
}

export const filterBuilderPost = ({ tags, searchTerm, byUser, sort, sortDirection = 'DESC', ...rest }: IFilter) => {
  const filterArr = []

  if (tags) {
    const tagFilter = (JSON.parse(tags) as string[]).map(tag => (`{"tags": {"$like": "%${tag}%"}}`)).join(',')
    filterArr.push(`{"$and": [${tagFilter}]}`)
  }
  if (searchTerm) {
    filterArr.push(`{"description": {"$like": "%${searchTerm}%"}}`)
    filterArr.push(`{"headline": {"$like": "%${searchTerm}%"}}`)
  }
  if (byUser) {
    filterArr.push(`{"user__id": {"$like": "%${byUser}%"}}`)
  }
  const sortFilter = sort
    ? { sortBy: [sort] }
    : undefined

  const sortDirFilter = sort
    ? { sortOrder: [(sortDirection as string)] }
    : undefined

  const filter = filterArr.length > 0 ? `{"$or": [${filterArr.join(',')}]}` : undefined
  return {
    filter,
    ...sortFilter,
    ...sortDirFilter,
    ...rest,
  }
}

export function filterBuilder (filter: IFilter, type: 'mixed'): ReturnType<typeof filterBuilderMixed>
export function filterBuilder (filter: IFilter, type?: 'hotel' | 'post'): ReturnType<typeof filterBuilderPost>
export function filterBuilder (filter: IFilter, type: 'mixed' | 'hotel' | 'post' = 'post') {
  if (type === 'mixed') {
    return filterBuilderMixed(filter)
  }

  return filterBuilderPost(filter)
}
