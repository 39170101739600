import { Box, Button, Container, Flex, Heading, Image, Skeleton, SkeletonText, useColorModeValue } from '@chakra-ui/react'
import { IonHeader, IonPage } from '@ionic/react'
import React, { useLayoutEffect, useMemo } from 'react'
import { useLocation, useParams } from 'react-router'
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js'
import Header from '../components/Header'
import Hotel from '../components/Hotel'
import { MotionFlex } from '../components/Motion'
import PageContent from '../components/PageContent'
import Post from '../components/Post'
import { useInspirationItem } from '../gql/queries/getInspirations'
import type { getInspirationItemQuery_getInspiration } from '../gql/queries/__generated__/getInspirationItemQuery'
import { getCompressedImageUrl } from '../helper/asset'

const InspirationDetail: React.FC = () => {
  const { hash } = useLocation()
  const { id } = useParams<{id: string}>()
  const { data, loading } = useInspirationItem(Number(id) ? parseInt(id) : id)
  const inspiration: getInspirationItemQuery_getInspiration | null | undefined = useMemo(() => data?.getBySlug?.element ?? data?.getInspiration, [data])
  const imageBg = useColorModeValue('gray.50', '#181818')

  useLayoutEffect(() => {
    if (hash) {
      setTimeout(() => {
        const element = document.getElementById(hash.replace('#', ''))
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' })
        }
      }, 750)
    }
  }, [hash])

  return (
    <IonPage>
      <IonHeader>
        <Header showBackButton/>
      </IonHeader>
      <PageContent>
        <Container scrollBehavior="smooth" maxW="container.md" p="0" pb="56px">
          {loading
            ? (
              <Box>
                <Skeleton height="200px"/>
                <Box p="1em">
                  <SkeletonText noOfLines={2} skeletonHeight="4"/>
                  <SkeletonText mt={10} noOfLines={12} skeletonHeight="3"/>
                </Box>
              </Box>
              )
            : (
                inspiration?.content?.map((item, index) => {
                  if (item?.__typename === 'fieldcollection_InspirationText') {
                    return (
                      <Box key={`dogstack-${index}`} p={5}>
                        <Heading
                          id={item.scrollAnchor ?? ''}
                          size="md"
                          _notLast={{
                            mb: 2,
                          }}
                        >{item.headline}
                        </Heading>
                        {item.text && (
                          <Box className="rich-text" dangerouslySetInnerHTML={{ __html: item.text.replace(/href="#(.+)"\s/gm, `href="/inspirations/${id}/#$1" `) }}/>
                        )}
                      </Box>
                    )
                  } else if (item?.__typename === 'fieldcollection_InspirationGallery') {
                    return (
                      <Box key={`dogstack-${index}`} onClick={e => e.stopPropagation()} bg={imageBg}>
                        <Swiper
                          zoom={{
                            maxRatio: 5,
                            minRatio: 1,
                          }}
                          scrollbar={{ hide: true }}
                          navigation
                          style={{ width: '100%' }}
                          virtual={true}
                          preloadImages={false}
                        >
                          {
                        item.gallery?.map((image, index) => image?.image?.fullpath && image.image.mimetype && (
                          <SwiperSlide key={`dogmedia-${index}`}>
                            <Flex
                              position="relative"
                              justifyContent="center"
                              alignContent="center"
                              alignItems="center"
                              cursor="pointer"
                            >
                              <MotionFlex
                                initial={{ opacity: 0 }}
                                pointerEvents="none"
                                position="absolute"
                                color="red.400"
                                fontSize="150px"
                                h="100%"
                                w="100%"
                                top="0"
                                left="0"
                                justifyContent="center"
                                alignItems="center"
                                zIndex={3}
                              />
                              <Box className="swiper-zoom-container" h={['200px', '300px', '400px']}>
                                <Image w="100%" h="100%" src={getCompressedImageUrl(image.image.fullpath)} key={`dog-picture-${index}`}/>
                              </Box>
                            </Flex>
                          </SwiperSlide>
                        ))
                      }
                        </Swiper>
                      </Box>
                    )
                  } else if (item?.__typename === 'fieldcollection_InspirationButton') {
                    return (
                      <Box key={`dogstack-${index}`} p={5}>
                        <Button colorScheme="primary" w="full" as="a" target={item.buttonTarget ?? '_blank'} href={item.buttonUrl?.path ?? '#'}>{item.buttonText ?? item.buttonUrl?.text}</Button>
                      </Box>
                    )
                  } else if (item?.__typename === 'fieldcollection_InspirationDogStories') {
                    return (
                      <Box key={`dogstack-${index}`}>
                        {item.posts?.map((post, index) => {
                          return <Post key={`dogdetailposts-${String(index)}${String(post?.id ?? '')}`} post={post}/>
                        })}
                      </Box>
                    )
                  } else if (item?.__typename === 'fieldcollection_InspirationHotels') {
                    return (
                      <Box key={`dogstack-${index}`}>
                        {item.hotels?.map((hotel, index) => {
                          return <Hotel key={`dogdetailhotels-${String(index)}${String(hotel?.id ?? '')}`} hotel={hotel}/>
                        })}
                      </Box>
                    )
                  } else if (item?.__typename === 'fieldcollection_InspirationAd') {
                    if (!item.adIframe) return null
                    return (
                      <Box key={`dogstack-${index}`} p={5} dangerouslySetInnerHTML={{ __html: String(item.adIframe) }}/>
                    )
                  }
                },
                )
              )}
        </Container>
      </PageContent>
    </IonPage>
  )
}

export default InspirationDetail
