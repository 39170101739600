import { gql, useMutation } from '@apollo/client'
import { apolloClient } from '../../helper/graphql'
import type { putUserForgotPasswordQuery, putUserForgotPasswordQueryVariables } from './__generated__/putUserForgotPasswordQuery'

const PUT_USER_FORGOT_PASSWORD = gql`
  mutation putUserForgotPasswordQuery(
    $email: String!
    $resetLink: String!
    $locale: String!
  ) {
    userForgotPassword(email: $email, resetLink: $resetLink, locale: $locale) {
      user {
        id
      }
    }
  }
`

export function putUserForgotPassword (email: string, resetLink: string, locale: string) {
  return apolloClient.mutate<putUserForgotPasswordQuery, putUserForgotPasswordQueryVariables>({
    mutation: PUT_USER_FORGOT_PASSWORD,
    variables: {
      email,
      resetLink,
      locale,
    },
  })
}

export function usePutUserForgotPassword () {
  return useMutation<putUserForgotPasswordQuery, putUserForgotPasswordQueryVariables>(PUT_USER_FORGOT_PASSWORD)
}
