import { Box, Container, Flex, Heading, Skeleton, Text, useColorModeValue, VStack } from '@chakra-ui/react'
import { IonHeader, IonImg, IonPage } from '@ionic/react'
import React, { useMemo } from 'react'
import Header from '../components/Header'
import PageContent from '../components/PageContent'
import Posts from '../components/Posts'
import SortBar from '../components/SortBar'
import TagsBar from '../components/TagsBar'
import { useFrontpageDetails } from '../gql/queries/getFrontpageDetails'
import { useUser } from '../helper/auth'

const Home: React.FC = () => {
  const { data: frontpageDetails, loading: detailsLoading } = useFrontpageDetails()
  const logo = useColorModeValue('/assets/logo.svg', '/assets/logo-white.svg')
  const user = useUser()

  const header = useMemo(() => {
    if (user.isLoggedIn) return

    return (
      <Container maxW="container.md" key="header">
        <VStack spacing="15px" align="flex-start">
          <Box mt={6}>
            <IonImg src={logo} alt="Tribello Logo"/>
          </Box>
          <Skeleton isLoaded={!detailsLoading}>
            <Heading mt="15px" fontFamily="Unkempt">{frontpageDetails?.getTribelloContentPage?.name}</Heading>
          </Skeleton>
          <Skeleton isLoaded={!detailsLoading}>
            <Text mb={4} dangerouslySetInnerHTML={{ __html: frontpageDetails?.getTribelloContentPage?.content ?? '' }}/>
          </Skeleton>
        </VStack>
      </Container>
    )
  }, [user, frontpageDetails, detailsLoading, logo])

  return (
    <IonPage>
      <IonHeader>
        <Header title="Home"/>
      </IonHeader>
      <PageContent disableScroll>
        <Flex direction="column" w="100%" h="100%">
          <Box>
            <TagsBar/>
            <SortBar/>
          </Box>
          <Posts header={header}/>
        </Flex>
      </PageContent>
    </IonPage>
  )
}

export default Home
