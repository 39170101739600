
import { Box, Button, Container, Flex, Heading, VStack } from '@chakra-ui/react'
import { IonHeader, IonPage, useIonViewWillEnter } from '@ionic/react'
import type { FormEvent } from 'react'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import type { OnChangeHandlerFunc } from 'react-mentions'
import { useParams } from 'react-router'
import Comment from '../components/Comment'
import Header from '../components/Header'
import MentionInput from '../components/MentionInput'
import PageContent from '../components/PageContent'
import Post from '../components/Post'
import PostSkeleton from '../components/Post/subComponents/PostSkeleton'
import PostDetailAdvertisement from '../components/PostDetailAdvertisement'
import { useCreateComment } from '../gql/mutations/putComment'
import { useComments } from '../gql/queries/getComments'
import { usePost } from '../gql/queries/getPost'
import type { getCommentsQuery, getCommentsQuery_getTribelloCommentListing_edges } from '../gql/queries/__generated__/getCommentsQuery'
import { getAuthToken, useUser } from '../helper/auth'
import createMention from '../helper/mention'

function commentLoadFilter (data?: getCommentsQuery) {
  const edges = data?.getTribelloCommentListing?.edges
  if (!edges) return []
  return edges.filter((edge): edge is getCommentsQuery_getTribelloCommentListing_edges => Boolean(edge?.node))
}

const PostDetail: React.FC = () => {
  const ref = useRef<HTMLIonContentElement>(null)
  const { id } = useParams<{id: string}>()
  const user = useUser()
  const [commentText, setCommentText] = useState('')
  const { data: postData, loading: postLoading } = usePost(id)
  const { data: commentsData, fetchMore: fetchMoreComments } = useComments(id, {
    first: 5,
    sortBy: ['o_id'],
    sortOrder: ['DESC'],
  })
  const [createComment, { loading: createCommentLoading }] = useCreateComment({ refetchQueries: ['getCommentsQuery'] })
  const comments = useMemo(() => commentLoadFilter(commentsData), [commentsData])

  const handleCreateComment = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!commentText) return

    setCommentText('')
    await createComment({
      variables: {
        content: commentText,
        postId: Number(id),
        userAccessToken: getAuthToken(),
      },
    })

    // creates a notification of type "mentioned" and sends it to the user
    createMention(id, commentText)
  }

  const loadMoreComments = () => {
    fetchMoreComments({
      variables: {
        after: comments.length,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const previousData = commentLoadFilter(previousResult)
        const newData = commentLoadFilter(fetchMoreResult)

        if (!newData.length) return previousResult

        return {
          getTribelloCommentListing: {
            edges: [
              ...previousData,
              ...newData,
            ],
            __typename: fetchMoreResult.getTribelloCommentListing?.__typename ?? 'TribelloCommentConnection',
            totalCount: fetchMoreResult.getTribelloCommentListing?.totalCount ?? 0,
          },
        }
      },
    })
  }

  const onMentionChange: OnChangeHandlerFunc = useCallback((e, value) => {
    setCommentText(value)
  }, [])

  useIonViewWillEnter(() => {
    ref.current?.scrollToTop()
  })

  return (
    <IonPage>
      <IonHeader>
        <Header showBackButton/>
      </IonHeader>
      <PageContent ref={ref}>
        {postLoading
          ? (
            <Container p="0" maxW="container.md">
              <PostSkeleton/>
            </Container>
            )
          : (
            <Container key="post-content" maxW="container.md" p="0" >
              <Post isDetail post={postData?.getTribelloPost} commentCount={commentsData?.getTribelloCommentListing?.totalCount ?? 0}/>
              <VStack
                mb="16px"
                spacing="6"
                align="stretch"
                px="16px"
              >
                {postData?.getTribelloPost?.advertisement?.map(advert => advert && (
                  <PostDetailAdvertisement key={advert.id} {...advert}/>
                ))}
                <Box
                  id="comment-form"
                  borderRadius="8px"
                  bg="var(--ion-card-background)"
                  p="16px"
                >
                  <form onSubmit={handleCreateComment}>
                    <VStack align="stretch">
                      <Heading size="sm">Kommentar hinterlassen</Heading>
                      <MentionInput
                        disabled={!user.isLoggedIn}
                        placeholder={!user.isLoggedIn ? 'Einloggen um kommentieren zu können.' : ''}
                        value={commentText}
                        onChange={onMentionChange}
                      />
                      <Flex justifyContent="flex-end">
                        <Button type="submit" colorScheme="primary" data-analytics-commet={`post ${postData?.getTribelloPost?.id ?? ''} ${postData?.getTribelloPost?.headline ?? ''}`} disabled={!user.isLoggedIn || createCommentLoading || commentText.length === 0} isLoading={createCommentLoading}>Antworten</Button>
                      </Flex>
                    </VStack>
                  </form>
                </Box>
              </VStack>
            </Container>
            )}
        {comments.map((comment, index) => (
          <Container
            key={`${id}-comment-${index}`}
            maxW="container.md"
            pb="16px"
          >
            <Comment comment={comment.node!}/>
          </Container>
        ))}
        {comments.length !== 0 && comments.length < (commentsData?.getTribelloCommentListing?.totalCount ?? 0) && (
          <Container
            maxW="container.md"
            pb="16px"
            textAlign="center"
          >
            <Button colorScheme="primary" size="sm" onClick={loadMoreComments}>mehr Kommentare laden</Button>
          </Container>
        )}
        <Box w="full" h="56px"/>
      </PageContent>
    </IonPage>
  )
}

export default PostDetail
