/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { Box, Flex, Grid, GridItem, Icon, Portal, Text, useBreakpointValue, useOutsideClick } from '@chakra-ui/react'
import { css } from '@emotion/css'
import { IonButton, IonIcon, useIonRouter } from '@ionic/react'
import { apps } from 'ionicons/icons'
import React, { useMemo, useRef, useState } from 'react'
import { MdBookmarkBorder, MdFingerprint, MdInfoOutline, MdOutlineChat, MdOutlinePublic, MdPersonOutline } from 'react-icons/md'
import { useUser } from '../../helper/auth'

const MoreButton: React.FC = () => {
  const navigation = useIonRouter()
  const menuWrapperRef = useRef(null)
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()
  const user = useUser()
  const [menuVisible, setMenuVisible] = useState(false)
  const right = useBreakpointValue({ base: '5', lg: '50%', xl: '50%', '2xl': '50%' })
  const marginRight = useBreakpointValue({ base: '0', lg: '-384px', xl: '-384px', '2xl': '-384px' })
  const menuItems = useMemo(() => [
    { icon: MdOutlineChat, label: 'Kontakt', route: 'mailto:tribello@eurotours.at' },
    { icon: MdInfoOutline, label: 'Impressum', route: '/content/impressum' },
    { icon: MdFingerprint, label: 'Datenschutz', route: '/content/datenschutzerklärung' },
    { icon: MdInfoOutline, label: 'AGB', route: '/content/agb' },
    { icon: MdPersonOutline, label: user.isLoggedIn ? 'Profil' : 'Login', route: user.isLoggedIn ? `/profile/${user.data?.user?.id ?? 0}` : '/login' },
    ...(user.isLoggedIn ? [{ icon: MdBookmarkBorder, label: 'Merkliste', route: '/saved' }] : [{}]),
    { icon: MdOutlinePublic, label: 'Inspirationen', route: '/inspirations' },
  ], [user]).filter(item => item.route)

  const handleMenuOpen = () => {
    setMenuVisible(true)
  }

  useOutsideClick({
    ref: menuWrapperRef,
    handler: () => {
      // prevent menu toggling on icon click
      clearTimeout(timeoutRef.current)
      timeoutRef.current = setTimeout(() => {
        setMenuVisible(false)
      }, 1)
    },
    enabled: menuVisible,
  })

  return (
    <>
      <IonIcon icon={apps}/>
      <Box w="100%" h="100%" pos="absolute" onClick={handleMenuOpen}/>
      {menuVisible && (
        // this is ultra bad practice, but since ionic is dogshit, there is no other (easy) way
        <Portal>
          <Box
            ref={menuWrapperRef}
            pos="absolute"
            background="var(--ion-card-background)"
            borderRadius="8px"
            className="hey find me"
            bottom="60px"
            right={right}
            marginRight={marginRight}
            boxShadow="0 1px 20px rgb(43 50 88 / 30%)"
          >
            <Grid templateRows={`repeat(${Math.ceil(menuItems.length / 3)}, 74px)`} templateColumns="repeat(3, 84px)" gap={2} padding={2} gridAutoFlow="dense">
              {menuItems.map(item => item.label && (
                <GridItem key={item.label}>
                  <IonButton
                    fill="clear"
                    href={item.route.startsWith('mailto:') ? item.route : undefined}
                    onClick={() => {
                      if (!item.route.startsWith('mailto:')) {
                        navigation.push(item.route, 'root')
                      }
                      setMenuVisible(!menuVisible)
                    }}
                    className={css`
                      --padding-start: 0;
                      --padding-end: 0;
                      --padding-top: 0;
                      --padding-bottom: 0;
                      margin-inline: 0;
                      margin: 0;
                      --color: var(--ion-color-step-600);
                      --color-activated: var(--ion-color-primary);
                      --color-focused: var(--ion-color-primary);
                      --color-hover: var(--ion-color-primary);
                      width: 100%;
                      height:100%;
                    `}
                  >
                    <Flex flexDir="column" align="center" justify="center">
                      <Icon w="24px" h="24px" as={item.icon}/>
                      <Text fontWeight="bold" marginTop={2} fontSize=".6em">{item.label}</Text>
                    </Flex>
                  </IonButton>
                </GridItem>
              ))}
            </Grid>
          </Box>
        </Portal>
      )}
    </>
  )
}

export default MoreButton
