/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { ChakraTheme } from '@chakra-ui/react'
import { extendTheme } from '@chakra-ui/react'
import { css, Global, useTheme } from '@emotion/react'
import Color from 'color'
import React from 'react'
import { DefaultVariables } from './default'

const primary = '#F0AE00'
const secondary = '#225B79'

function getShades (color: string) {
  return {
    50: Color(color).lighten(0.5).hex(),
    100: Color(color).lighten(0.4).hex(),
    200: Color(color).lighten(0.3).hex(),
    300: Color(color).lighten(0.2).hex(),
    400: Color(color).lighten(0.1).hex(),
    500: color,
    600: Color(color).darken(0.1).hex(),
    700: Color(color).darken(0.2).hex(),
    800: Color(color).darken(0.3).hex(),
    900: Color(color).darken(0.4).hex(),
  }
}

const theme: Partial<ChakraTheme> = {
  fonts: {
    heading: 'Open Sans',
    body: 'Open Sans',
  },
  shadows: {
    outline: `0 0 0 3px ${Color(primary).alpha(0.5).toString()}`,
    active: `0 0 0 3px ${Color(primary).alpha(0.5).toString()}`,
  },
  colors: {
    primary: getShades(primary),
    secondary: getShades(secondary),
    tribelloBackground: '#f0f2f5',
  },
  config: {
    useSystemColorMode: true,
  },
}

export const chakraTheme = extendTheme(theme)

export const Variables: React.FC = () => {
  const theme = useTheme() as typeof chakraTheme

  return (
    <>
      <DefaultVariables/>
      <Global styles={css`

      ion-content {        
        --padding-bottom: 56px;
      }

      :root {
        --ion-color-primary-tint: ${theme.colors.primary[400]};
        --ion-color-primary: ${theme.colors.primary[500]};
        --ion-color-primary-shade: ${theme.colors.primary[600]};
        --ion-color-primary-rgb: ${Color(theme.colors.primary[500]).rgb().array().join(',')};
        --ion-color-primary-contrast: #ffffff;
        --ion-color-primary-contrast-rgb: 255, 255, 255;
        --ion-font-family: ${theme.fonts.body};
        --ion-item-color: ${theme.colors.black};
        --ion-background-color: ${theme.colors.tribelloBackground};
        --ion-card-background: ${theme.colors.white};        
        --ion-tab-bar-background: rgb(255 255 255 / 75%);
        /* --ion-tab-bar-color: ${theme.colors.black}; */
        
        --swiper-navigation-color: ${theme.colors.primary[500]};

        .bg-clear {
          --ion-background-color: ${theme.colors.white};
        }
      }

      @media (prefers-color-scheme: dark) {
        body,
        body .md, 
        body .iso {
          --ion-color-primary-tint: ${theme.colors.primary[200]};
          --ion-color-primary: ${theme.colors.primary[300]};
          --ion-color-primary-shade: ${theme.colors.primary[400]};
          --ion-color-primary-rgb: ${Color(theme.colors.primary[200]).rgb().array().join(',')};
          --ion-color-primary-contrast: #ffffff;
          --ion-color-primary-contrast-rgb: 255, 255, 255;
          --ion-font-family: ${theme.fonts.body};
          --ion-item-color: ${theme.colors.white};          
          /* --ion-background-color: ${theme.colors.gray[100]}; */
          /* --ion-card-background: ${theme.colors.gray[900]}; */
          --ion-tab-bar-background: rgb(30 30 30 / 75%);
          --swiper-navigation-color: ${theme.colors.primary[300]};
          /* --ion-tab-bar-color: ${theme.colors.white}; */

          .swiper-scrollbar {
            background: rgb(255 255 255 / 10%);
            .swiper-scrollbar-drag {
              background: rgb(255 255 255 / 50%);
            }
          }

          .bg-clear {
            --ion-background-color: inherit !important;
          }
        }
      }
      
      .rich-text {
        ul, ol {
          margin: 0;
          padding: 0;
          padding-left: ${theme.space[5]};
          margin-top: ${theme.space[5]};
        }
        p {
            margin-top: ${theme.space[5]};
        }
      }
    `}
      />
    </>
  )
}
