import { gql, useQuery } from '@apollo/client'
import { apolloClient } from '../../helper/graphql'
import { USER_FRAGMENT } from './getUser'
import type { getNotificationsQuery, getNotificationsQueryVariables } from './__generated__/getNotificationsQuery'

const GET_NOTIFICATIONS = gql`
  query getNotificationsQuery ($filter: String!, $filterUnread: String, $includeUnread: Boolean = false, $first: Int, $after: Int, $includeProfileData: Boolean = false) {
    getTribelloNotificationListing(filter: $filter, first: $first, after: $after) {
      edges {
        node {
          id
          notificationType
          post {
            ...on object_TribelloPost {
              headline
              id
            }
          }
          read
          creationDate
          sender {
            ...UserFragment
          }
        }
      }
      totalCount
    }
    
    getUnreadNotifications: getTribelloNotificationListing(filter: $filterUnread) @include(if: $includeUnread)  {
      totalUnread: totalCount
    }
  }
  ${USER_FRAGMENT}
`

export async function getNotification (recieverId: number, includeUnread?: boolean, first?: number, after?: number) {
  return apolloClient.query<getNotificationsQuery, getNotificationsQueryVariables>({
    query: GET_NOTIFICATIONS,
    variables: {
      ...filterBuilder(recieverId, includeUnread),
      first,
      after,
      includeUnread,
    },
  })
}

export async function useNotification (recieverId: number, includeUnread?: boolean, first?: number, after?: number) {
  return useQuery<getNotificationsQuery, getNotificationsQueryVariables>(GET_NOTIFICATIONS, {
    variables: {
      ...filterBuilder(recieverId, includeUnread),
      first,
      after,
      includeUnread,
    },
  })
}

function filterBuilder (recieverId: number, includeUnread?: boolean) {
  return {
    filter: `{"receiver__id": {"$like": "%${recieverId}%"}}`,
    filterUnread: includeUnread ? `{"receiver__id": {"$like": "%${recieverId}%"}, "read": {"$not": "1"}}` : undefined,
  }
}
