import { Box, Flex, Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react'
import React from 'react'

const PostSkeleton: React.FC<{type?: 'post' | 'hotel' | 'inspiration'}> = ({ type = 'post' }) => (
  <Box
    borderRadius="8px"
    bg="var(--ion-card-background)"
    m="16px"
    pb="1rem"
  >
    <Flex p="1em" align="center" gap={3}>
      {type === 'post' && <SkeletonCircle size="12"/>}
      <Flex flexDir="column">
        <SkeletonText noOfLines={1} width="10rem" skeletonHeight="4"/>
        {type !== 'inspiration' && <SkeletonText mt="3" noOfLines={1} width="6rem" skeletonHeight="3"/>}
      </Flex>
    </Flex>
    <Skeleton height={type === 'post' ? '25rem' : '12rem'}/>
    <Box p="1em">
      <SkeletonText noOfLines={1} width="10rem" skeletonHeight="4"/>
      <SkeletonText mt="4" noOfLines={1} width="12rem" skeletonHeight="3"/>
      <SkeletonText mt="4" noOfLines={3} spacing="2" skeletonHeight="2"/>
      <SkeletonText mt="4" noOfLines={1} width="6rem" skeletonHeight="3"/>
    </Box>
  </Box>
)

export default PostSkeleton
