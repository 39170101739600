import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { createStandaloneToast, theme } from '@chakra-ui/react'
import { removeAuthToken, removeAuthTokenStamp } from './auth'
import { GRAPHQL_ENDPOINT } from './enviroment'
const customToast = createStandaloneToast({ theme })

function nullable () {
  // Create a generic field policy that allows any field to be null by default:
  return {
    read (existing = null) {
      return existing
    },
  }
}

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      object_TribelloTagType: {
        fields: {
          id: nullable(),
          name: nullable(),
          description: nullable(),
        },
      },
      asset: {
        fields: {
          mimetype: nullable(),
          fullpath: nullable(),
        },
      },
    },
  },
  ),
  link: ApolloLink.from([
    onError(({ response }) => {
      // @ts-expect-error
      response?.errors?.forEach(({ translationKey, message }) => {
        // FIXME: Do this properly, i don't have access to an expired token right now so that's why i gotta do it in this disgusting way
        if (message.includes('Unauthorized')) {
          removeAuthToken()
          removeAuthTokenStamp()
        }
        if (translationKey === 'cc-no-customer-found-with-this-credentials') {
          customToast({
            title: 'Es ist ein Fehler aufgetreten',
            description: 'Mit dieser Mailadresse existiert bereits ein Account. Bitte nutze die Passwort vergessen Funktion, wenn du ein neues Passwort setzen möchtest.',
            duration: 3000,
            status: 'error',
          })
        } else {
          customToast({
            title: 'Es ist ein Fehler aufgetreten',
            description: message,
            duration: 3000,
            status: 'error',
          })
        }
        console.error('error occured during graphql request:', message)
      })

      // this is not a hack: https://www.apollographql.com/docs/react/data/error-handling/#ignoring-errors
      if (response?.errors) response.errors = undefined
    }),
    new HttpLink({
      // useGETForQueries: true,
      uri: GRAPHQL_ENDPOINT,
    }),
  ]),

  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
  // connectToDevTools: true,
})
