/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { Box, Button, Heading, SkeletonText, Text } from '@chakra-ui/react'
import React, { useMemo, useRef } from 'react'
import { useMeasure } from 'react-use'
// import type { ITagType } from '../../../gql/queries/getTags'
import type { getHotelQuery_getTribelloHotel } from '../../../gql/queries/__generated__/getHotelQuery'
import Interactions from '../../Interactions'

const HotelContent: React.FC<{hotel: getHotelQuery_getTribelloHotel | null | undefined, likeHotel: (value: boolean) => void}> = ({ likeHotel, hotel }) => {
  const [textRef, { height: textHeight }] = useMeasure<HTMLParagraphElement>()
  const spanRef = useRef<HTMLSpanElement>(null)

  // if this doesn't work, add spanref.current to dependencies
  const isTextCut = useMemo(() => {
    if (!textHeight || !spanRef.current) {
      return false
    }

    return textHeight < spanRef.current.offsetHeight
  }, [textHeight])

  if (!hotel) {
    return (
      <Box padding="20px">
        <SkeletonText mt="4" noOfLines={4} spacing="4"/>
      </Box>
    )
  }

  return (
    <Box padding="20px">
      <Heading size="md" mb={2}>{hotel.name}</Heading>
      {hotel.country?.isoCode && <Text opacity={0.5} mt="-3px" mb="5px">{hotel.region} ({hotel.country.isoCode})</Text>}
      <Text ref={textRef} whiteSpace="pre-line" noOfLines={3}>
        <span ref={spanRef} dangerouslySetInnerHTML={{ __html: hotel.description?.replace(/(<([^>]+)>)/ig, '') ?? '' }}/>
      </Text>
      {isTextCut && (
        <Button mt={5} mb={5} colorScheme="primary" w="full">Mehr lesen</Button>
      )}
      <Interactions onLiked={likeHotel} hotel={hotel}/>
    </Box>
  )
}

export default HotelContent
