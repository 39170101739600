import { gql, useQuery } from '@apollo/client'
import { apolloClient } from '../../helper/graphql'
import type { getUsersQuery, getUsersQueryVariables } from './__generated__/getUsersQuery'

export const GET_USERS = gql`
  query getUsersQuery ($filter: String, $first: Int, $after: Int) {
    getCoreShopCustomerListing(
      filter: $filter
      first: $first
      after: $after
    ) {
      edges {
        node {
          id
          user {
            ... on object_CoreShopUser {
              id
            } 
          }
          firstname
          lastname
        }
      }
    }
  }
`

function getFilter (name: string) {
  return `{"fullname": {"$like": "%${name}%"}}`
}

export function getUsers (name: string, limit = 10, skip = 0) {
  return apolloClient.query<getUsersQuery, getUsersQueryVariables>({
    query: GET_USERS,
    variables: {
      filter: getFilter(name),
      first: limit,
      after: skip,
    },
  })
}

export function useUsers (name: string, limit = 10, skip = 0) {
  return useQuery<getUsersQuery, getUsersQueryVariables>(GET_USERS, {
    variables: {
      filter: getFilter(name),
      first: limit,
      after: skip,
    },
  })
}
