import { Center, Container, Flex, Heading, Spacer, Text } from '@chakra-ui/react'
import { IonHeader, IonPage, IonRouterLink } from '@ionic/react'
import React from 'react'
import Header from '../components/Header'
import PageContent from '../components/PageContent'

const NotFound: React.FC = () => {
  return (
    <IonPage>
      <IonHeader>
        <Header title="404"/>
      </IonHeader>
      <PageContent>
        <Container maxW="container.lg"/>
        <Center h="100%">
          <Flex align="center">
            <Flex w="50%" flexDir="column">
              <Heading mb={3}>404: Hoppla!</Heading>
              <Text mb={3}>Oh nein, diese Seite existiert (noch) nicht.</Text>
              <Spacer/>
              <IonRouterLink href="/home">
                zurück zur Startseite
              </IonRouterLink>
            </Flex>
            {/* <Flex w="100%" justify="center">
              <Image src="https://via.placeholder.com/256" alt="Page Not Found Image"/>
            </Flex> */}
          </Flex>
        </Center>
      </PageContent>
    </IonPage>
  )
}

export default NotFound
