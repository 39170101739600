import { Box, useToast } from '@chakra-ui/react'
import { IonButton, IonButtons, IonIcon, IonItem, IonList, IonPopover, useIonRouter } from '@ionic/react'
import { create, ellipsisVertical, flag, trash } from 'ionicons/icons'
import React, { useCallback } from 'react'
import { useLocation } from 'react-router'
import { useDeletePost } from '../../../../../gql/mutations/putDeletePost'
import { useReportPost } from '../../../../../gql/mutations/putReportPost'
import { getAuthToken, useUser } from '../../../../../helper/auth'
import type { IPopOverState } from '../../../../../types/IPopOverState'
import type { IHeaderEditProps } from './PostHeaderEdit'

interface IProps extends IHeaderEditProps {
  popoverState: IPopOverState
  setShowPopover: (state: IPopOverState) => void
}

const HeaderMenu: React.FC<IProps> = ({ post, popoverState, setShowPopover, setShowModal }) => {
  const [deletePost, { loading }] = useDeletePost({ refetchQueries: ['getMixedQuery', 'getPostsQuery'] })
  const toast = useToast()
  const user = useUser()
  const navigation = useIonRouter()
  const { pathname } = useLocation()
  const [reportPost] = useReportPost()

  const deletePostHandler = useCallback(async () => {
    if (post?.id) {
      const res = await deletePost({
        variables: {
          postId: Number(post.id),
          userAccessToken: getAuthToken(),
        },
      })

      if (res.data?.deletePost?.success && pathname.match(/posts\/\d{2,}/g)) {
        navigation.push('/home')
      }
    }

    setShowPopover({
      showPopover: false,
    })
  }, [post, setShowPopover, deletePost, pathname, navigation])

  const handleReport = async () => {
    if (post?.id) {
      await reportPost({
        variables: {
          postId: post.id,
        },
      })
    }

    toast({ status: 'info', title: 'Gemeldet', description: 'Vielen Dank, der Post wurde erfolgreich gemeldet!' })
  }

  return (
    <>
      <IonPopover
        mode="md"
        event={popoverState.event}
        isOpen={popoverState.showPopover}
        onDidDismiss={() => setShowPopover({ showPopover: false })}
      >
        <IonList onClick={e => e.stopPropagation()}>
          {user.data?.user?.id === post?.user?.id && (
            <>
              <IonItem
                button
                onClick={() => {
                  setShowPopover({ showPopover: false })
                  setShowModal(true)
                }}
              >
                <IonIcon icon={create}/>
                <Box pl=".5em">
                  Editieren
                </Box>
              </IonItem>
              <IonItem button onClick={deletePostHandler}>
                <IonIcon icon={trash}/>
                <Box pl=".5em">
                  Löschen
                </Box>
              </IonItem>
            </>
          )}
          <IonItem button lines="none" onClick={handleReport}>
            <IonIcon icon={flag}/>
            <Box pl=".5em">
              Melden
            </Box>
          </IonItem>
        </IonList>
      </IonPopover>
      <IonButtons onClick={e => e.stopPropagation()}>
        <IonButton
          mode="md"
          shape="round"
          slot="icon-only"
          onClick={(e) => setShowPopover({ showPopover: true, event: e.nativeEvent })}
          disabled={loading}
        >
          <IonIcon icon={ellipsisVertical}/>
        </IonButton>
      </IonButtons>
    </>
  )
}

export default HeaderMenu
