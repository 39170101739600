import { Box, Flex } from '@chakra-ui/react'
import { IonHeader, IonPage } from '@ionic/react'
import React from 'react'
import Header from '../components/Header'
import PageContent from '../components/PageContent'
import Posts from '../components/Posts'
import TagsBar from '../components/TagsBar'

const HotelList: React.FC = () => (
  <IonPage>
    <IonHeader>
      <Header title="Hotel"/>
    </IonHeader>
    <PageContent disableScroll>
      <Flex direction="column" w="100%" h="100%">
        <Box>
          <TagsBar/>
        </Box>
        <Posts type="hotel" perPage={4}/>
      </Flex>
    </PageContent>
  </IonPage>
)

export default HotelList
