import { Box, Flex, Heading, HStack } from '@chakra-ui/react'
import { useIonRouter } from '@ionic/react'
import { useAnimation } from 'framer-motion'
import React, { useCallback } from 'react'
import type { getInspirationListQuery_getInspirationListing_edges_node as IInspiration } from '../../gql/queries/__generated__/getInspirationListQuery'
import InspirationContent from './subComponents/InspirationContent'
import InspirationMedia from './subComponents/InspirationMedia'

const InspirationElement: React.FC<{inspiration: IInspiration | null | undefined }> = ({ inspiration }) => {
  const navigation = useIonRouter()
  const animationControl = useAnimation()

  const likeInspirationAnimation = useCallback((value: boolean) => {
    if (!value) return

    animationControl.start({
      opacity: [1, 1, 1, 0],
      scale: [0, 1, 1, 0],
    })
  }, [animationControl])

  return (
    <Box
      borderRadius="8px"
      bg="var(--ion-card-background)"
      m="16px"
      transition=".35s"
      cursor="pointer"
      border="2px solid transparent"
      _hover={{
        border: '2px solid rgba(var(--ion-color-primary-rgb), 0.2)',
      }}
      _active={{
        border: '2px solid var(--ion-color-primary)',
      }}
      onClick={() => navigation.push(`/inspirations/${String(inspiration?.slug ?? inspiration?.id ?? '')}`)}
    >
      <Flex justifyContent="space-between" p="1em" align="center">
        <Flex align="center" onClick={e => e.stopPropagation()}>
          <Box>
            <HStack spacing="3">
              <Flex flexDir="column">
                <Heading size="sm" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                  Inspiration
                </Heading>
              </Flex>
            </HStack>
          </Box>
        </Flex>
      </Flex>
      <InspirationMedia inspiration={inspiration} animationControl={animationControl}/>
      <InspirationContent inspiration={inspiration} likeInspiration={likeInspirationAnimation}/>
    </Box>
  )
}

export default InspirationElement
