import { Box, Flex, Heading, HStack } from '@chakra-ui/react'
import React from 'react'

const TribelloAd: React.FC<{html: string }> = ({ html }) => {
  return (
    <Box
      borderRadius="8px"
      bg="var(--ion-card-background)"
      m="16px"
      transition=".35s"
      cursor="pointer"
      border="2px solid transparent"
      _hover={{
        border: '2px solid rgba(var(--ion-color-primary-rgb), 0.2)',
      }}
      _active={{
        border: '2px solid var(--ion-color-primary)',
      }}
    >
      <Flex justifyContent="space-between" p="1em" align="center">
        <Flex align="center" onClick={e => e.stopPropagation()}>
          <Box>
            <HStack spacing="3">
              <Flex flexDir="column">
                <Heading size="sm" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                  Gesponsert
                </Heading>

              </Flex>
            </HStack>
          </Box>
        </Flex>
      </Flex>
      <div dangerouslySetInnerHTML={{ __html: html }}/>
    </Box>
  )
}

export default TribelloAd
