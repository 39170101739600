import { Box } from '@chakra-ui/react'
import { IonButton, IonButtons, IonIcon, IonItem, IonList, IonPopover } from '@ionic/react'
import { create, flag, key, logOut, settingsSharp } from 'ionicons/icons'
import React, { useState } from 'react'
import { useUser } from '../helper/auth'
import type { IPopOverState } from '../types/IPopOverState'
import ChangePasswordModal from './ChangePasswordModal'

const ProfileOptions: React.FC<{ userId?: string | null, onEdit?: () => void }> = ({ userId, onEdit }) => {
  const user = useUser()
  const [passwordModalOpen, setPasswordModalOpen] = useState(false)

  const [popoverState, setShowPopover] = useState<IPopOverState>({
    showPopover: false,
    event: undefined,
  })

  return (
    <>
      <IonPopover
        mode="md"
        event={popoverState.event}
        isOpen={popoverState.showPopover}
        onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
      >
        <IonList onClick={e => e.stopPropagation()}>
          {userId && userId === user.data?.user?.id
            ? (
              <>
                <IonItem button lines="none" onClick={onEdit}>
                  <IonIcon icon={create}/>
                  <Box pl=".5em">
                    Profil bearbeiten
                  </Box>
                </IonItem>
                <IonItem button lines="none" onClick={() => setPasswordModalOpen(true)}>
                  <IonIcon icon={key}/>
                  <Box pl=".5em">
                    Passwort ändern
                  </Box>
                </IonItem>
                <IonItem
                  button
                  lines="none"
                  onClick={() => {
                    user.logout?.()
                  }}
                >
                  <IonIcon icon={logOut}/>
                  <Box pl=".5em">
                    Ausloggen
                  </Box>
                </IonItem>
              </>
              )
            : (
              <IonItem button lines="none">
                <IonIcon icon={flag}/>
                <Box pl=".5em">
                  Melden
                </Box>
              </IonItem>
              )}
        </IonList>
      </IonPopover>
      <IonButtons onClick={e => e.stopPropagation()}>
        <IonButton
          mode="md"
          shape="round"
          slot="icon-only"
          onClick={(e) => setShowPopover({ showPopover: true, event: e.nativeEvent })}
        >
          <IonIcon icon={settingsSharp}/>
        </IonButton>
      </IonButtons>
      <ChangePasswordModal open={passwordModalOpen} setOpen={setPasswordModalOpen}/>
    </>
  )
}

export default ProfileOptions
