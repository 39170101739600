import { createMetaStore } from './meta'
import useSWR from 'swr'
import { getNotification } from '../gql/queries/getNotifications'
import { useUser } from './auth'

const [NotificationsProvider, useNotifications] = createMetaStore(() => {
  const user = useUser()

  const data = useSWR(user.data?.user?.id, id => getNotification(Number(id), true, 0, 0), {
    revalidateOnMount: true,
    refreshInterval: 10000,
  })

  const notificationData = {
    notifications: data.data?.data.getTribelloNotificationListing,
    totalUnread: data.data?.data.getUnreadNotifications?.totalUnread,
  }

  return {
    count: notificationData.notifications?.totalCount,
    loading: data.isValidating,
    data: notificationData.notifications?.edges?.map(edge => edge?.node),
    totalUnread: notificationData.totalUnread,
  }
})

export { useNotifications, NotificationsProvider }
