import { Box, Link } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { Link as RouterLink } from 'react-router-dom'

const MentionDisplay: React.FC<{ mentionString: string }> = ({ mentionString }) => {
  const parsedMentions = useMemo(() => {
    if (!mentionString) return []
    const content: Array<JSX.Element | string> = []
    let contentIndex = 0

    const matches = mentionString.matchAll(/@\[(.+?)\]\((\d+?)\)/g)

    for (;;) {
      const match = matches.next()
      if (match.done) {
        content.push(mentionString.substring(contentIndex))
        break
      }

      const [matchedString, username, userId] = match.value
      const matchIndex = match.value.index
      if (typeof matchIndex === 'undefined') continue

      content.push(mentionString.substring(contentIndex, matchIndex))
      content.push(
        <Link as={RouterLink} color="primary.500" key={`mention-${userId}`} to={`/profile/${userId}`}>
          {username}
        </Link>,
      )

      contentIndex = matchIndex + matchedString.length
    }

    return content
  }, [mentionString])

  return <Box>{parsedMentions}</Box>
}

export default MentionDisplay
