import { Avatar, AvatarBadge, Box, HStack, Spacer, Text } from '@chakra-ui/react'
import dayjs from 'dayjs'
import de from 'dayjs/locale/de-at'
import React from 'react'
import { Link } from 'react-router-dom'
import type { getNotificationsQuery_getTribelloNotificationListing_edges_node as INotification } from '../gql/queries/__generated__/getNotificationsQuery'
import { getCompressedImageUrl } from '../helper/asset'

dayjs.locale(de)

const Notification: React.FC<INotification & {markAsRead: () => void}> = ({ post, read, creationDate, sender, notificationType, markAsRead }) => {
  return (
    <HStack as={Link} borderRadius="8px" bg="var(--ion-card-background)" p={3} w="100%" to={`/posts/${post?.id ?? '0'}`} onClick={() => markAsRead()}>
      <Avatar src={getCompressedImageUrl(sender?.customer?.images?.[0]?.fullpath)}>
        {!read && (
          <AvatarBadge boxSize="1.1em" bg="red.300"/>
        )}
      </Avatar>
      <Spacer/>
      <Box>
        <Text>{sender?.customer?.firstname} {notificationType === 'postLiked' ? 'hat deine Dogstory geliked!' : 'hat dich in einem Post markiert!'}</Text>
        <Text alignSelf="flex-start" opacity={0.5}>{dayjs(Number(creationDate) * 1000).fromNow()}</Text>
      </Box>
    </HStack>
  )
}

export default Notification
