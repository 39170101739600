import { gql, useQuery } from '@apollo/client'
import { apolloClient } from '../../helper/graphql'
import type { getRegistrationDetailsQuery } from './__generated__/getRegistrationDetailsQuery'

const GET_DETAILS = gql`
  query getRegistrationPopupDetailsQuery {
    getTribelloContentPage(fullpath:"/tribello/contents/registration-pop-up") {
      name
      content
    }
  }
`

export async function getRegistrationPopupDetails () {
  return apolloClient.query<getRegistrationDetailsQuery>({
    query: GET_DETAILS,
  })
}

export function useRegistrationPopupDetails () {
  return useQuery<getRegistrationDetailsQuery>(GET_DETAILS)
}
