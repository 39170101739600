import { gql, useQuery } from '@apollo/client'
import { HOTEL_FRAGMENT } from './getHotel'
import { POST_FRAGMENT } from './getPost'
import type { getInspirationItemQuery, getInspirationItemQueryVariables } from './__generated__/getInspirationItemQuery'

export const INSPIRATION_FRAGMENT = gql`
fragment InspirationFragment on object_Inspiration {
  id
  slug
  name
  teaser
  likesCount
  images {
    image {
      fullpath(thumbnail:"hotel")
      mimetype
    }
  }
  content {
    ... on fieldcollection_InspirationGallery {
      gallery {
        image {
          fullpath
        }
      }
    }
  }
}
`

export const GET_INSPIRATION_LIST = gql`
  query getInspirationListQuery(
    $sortBy: [String]
    $first: Int 
    $after: Int
  ) {
    getInspirationListing (
      sortBy: $sortBy
      first: $first
      after: $after
    ) {
      totalCount
      edges {
        node {
          ...InspirationFragment
        }
      }
    }
  }
  ${INSPIRATION_FRAGMENT}
`
export const INSPIRATION_FRAGMENT_CONTENT = gql`
fragment InspirationFragment on object_Inspiration {
  id
  name
  teaser
  likesCount
  content {
    ... on fieldcollection_InspirationText {
      __typename
      headline
      scrollAnchor
      text
    }
    ... on fieldcollection_InspirationGallery {
      __typename
      gallery {
        image {
          fullpath(thumbnail: "hotel")
          mimetype
        }
      }
    }
    ... on fieldcollection_InspirationButton {
      __typename
      buttonText
      buttonUrl {
        text
        path
      }
      buttonTarget
    }
    ... on fieldcollection_InspirationDogStories {
      __typename
      posts {
        ...PostFragment
      }
    }
    ... on fieldcollection_InspirationHotels {
      __typename
      hotels {
        ...HotelFragment
      }
    }
    ... on fieldcollection_InspirationAd {
      __typename
      adIframe
    }
  }
}
${POST_FRAGMENT}
${HOTEL_FRAGMENT}
`

export const GET_INSPIRATION_ITEM_BY_ID = gql`
query getInspirationItemQuery ($id: Int!, $includeProfileData: Boolean = false) {
  getInspiration(id: $id) {
    ...InspirationFragment
  }
}
${INSPIRATION_FRAGMENT_CONTENT}
`

export const GET_INSPIRATION_ITEM_BY_SLUG = gql`
query getInspirationItemQuery ($slug: String!, $includeProfileData: Boolean = false) {
  getBySlug(slug: $slug, type: "inspiration") {
    element {
    ...InspirationFragment
    }
  }
}
${INSPIRATION_FRAGMENT_CONTENT}
`

export function useInspirationItem (id: number | string) {
  return useQuery<getInspirationItemQuery, getInspirationItemQueryVariables>(typeof id === 'number' ? GET_INSPIRATION_ITEM_BY_ID : GET_INSPIRATION_ITEM_BY_SLUG, {
    variables: {
      id: typeof id === 'number' ? id : undefined,
      slug: typeof id === 'string' ? id : undefined,
    },
  })
}
