import { Center, Container, Heading, Spinner, Text } from '@chakra-ui/react'
import { IonIcon } from '@ionic/react'
import { paw } from 'ionicons/icons'
import React from 'react'

export const EndFeed: React.FC<{isLoading: boolean}> = ({ isLoading }) => (
  <Container p="0" maxW="container.md" paddingBottom="76px">
    {isLoading
      ? (
        <Center>
          <Spinner/>
        </Center>
        )
      : (
        <>
          <Center fontSize="6em" color="primary.500">
            <IonIcon icon={paw}/>
          </Center>
          <Heading textAlign="center">Thats all Folks</Heading>
          <Text textAlign="center">Sieht so aus als hättest du das Ende erreicht.</Text>
        </>
        )}
  </Container>
)
