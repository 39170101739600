import { gql, useQuery } from '@apollo/client'
import { apolloClient } from '../../helper/graphql'
import type { getFrontpageDetailsQuery } from './__generated__/getFrontpageDetailsQuery'

const GET_DETAILS = gql`
query getFrontpageDetailsQuery {
  getTribelloContentPage(fullpath:"/tribello/contents/homepage") {
    name
    content
  }
}
`

export async function getFrontpageDetails () {
  return apolloClient.query<getFrontpageDetailsQuery>({
    query: GET_DETAILS,
  })
}

export function useFrontpageDetails () {
  return useQuery<getFrontpageDetailsQuery>(GET_DETAILS)
}
