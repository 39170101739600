/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Icon, Portal } from '@chakra-ui/react'
import { css } from '@emotion/css'
import { IonIcon, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, useIonRouter } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import type { FileAddedCallback } from '@uppy/core'
import { Uppy } from '@uppy/core'
import '@uppy/image-editor/dist/style.css'
// @ts-expect-error There are no types...
import German from '@uppy/locales/lib/de_DE'
import { useUppy } from '@uppy/react'
import Tus from '@uppy/tus'
import React, { useCallback, useMemo, useRef } from 'react'
import { MdAddCircle, MdOutlineHotel, MdSearch } from 'react-icons/md'
import { Redirect, Route, useLocation } from 'react-router'
import FileInputButton from '../components/FileInputButton'
import FilterModal from '../components/FilterModal'
import MoreButton from '../components/Navigation/MoreButton'
import UploadModal from '../components/UploadModal'
import { getAuthToken, useUser } from '../helper/auth'
import { UPPY_ENDPOINT } from '../helper/enviroment'
import { useFilterDisclosure } from '../helper/filterDisclosure'
import { useUpload } from '../helper/upload'
import NotFound from '../pages/404'
import BookmarkList from '../pages/BookmarkList'
import Content from '../pages/Content'
import Home from '../pages/Home'
import HotelDetail from '../pages/HotelDetail'
import HotelList from '../pages/HotelList'
import Inspiration from '../pages/Inspiration'
import InspirationDetail from '../pages/InspirationDetail'
import Login from '../pages/Login'
import PostDetail from '../pages/PostDetail'
import Profile from '../pages/Profile'
import ResetPassword from '../pages/ResetPassword'
export const Router = () => (
  <IonReactRouter>
    <Tabs/>
    <FilterModal/>
  </IonReactRouter>
)

const Tabs: React.FC = () => {
  const navigation = useIonRouter()
  const location = useLocation()
  const user = useUser()
  const filterDisclosure = useFilterDisclosure()
  const parentComponentRef = useRef<HTMLIonRouterOutletElement | null>(null)
  const modalState = useUpload()

  const debugLogger = useMemo(() => ({
    debug: (...args: any) => console.debug('[Uppy]', ...args),
    warn: (...args: any) => console.warn('[Uppy]', ...args),
    error: (...args: any) => console.error('[Uppy]', ...args),
  }), [])

  const uppy = useUppy(() => {
    return new Uppy({
      logger: debugLogger,
      locale: German,
      restrictions: {
        allowedFileTypes: ['image/png', 'image/jpg', 'image/jpeg', 'image/gif', 'video/mp4', 'application/x-mpegURL', 'video/MP2T', 'video/3gpp', 'video/quicktime', 'video/x-msvideo', 'video/x-ms-wmv'],
        maxNumberOfFiles: 10,
        maxFileSize: 300 * 1024 * 1024,
      },
    })
      .use(Tus, {
        endpoint: UPPY_ENDPOINT,
        headers: { Authorization: `Bearer ${getAuthToken()}` },
      })
  })

  const onFileAdded = useCallback<FileAddedCallback<Record<string, unknown>>>((file) => {
    if (!modalState.uploadModalOpen) {
      modalState.setUploadModalOpen?.(true)
    }
    uppy.getPlugin('react:Dashboard')?.setOptions({
      disabled: false,
    })
  }, [modalState, uppy])

  const handleDismiss = useCallback(() => {
    uppy.reset()
    modalState.setFiles?.([])
    modalState.setUploadModalOpen?.(false)
  }, [modalState, uppy])

  return (
    <>
      <IonTabs>
        <IonRouterOutlet>
          <Route exact path="/" component={() => <Redirect to="/home"/>}/>
          <Route exact path="/home" component={Home}/>
          <Route path="/posts/:id" component={PostDetail}/>
          <Route exact path="/hotels" component={HotelList}/>
          <Route path="/hotels/:id" component={HotelDetail}/>
          <Route exact path="/inspirations" component={Inspiration}/>
          <Route path="/inspirations/:id" component={InspirationDetail}/>
          <Route path="/profile/:userId" component={Profile}/>
          <Route path="/content/:path" component={Content}/>
          <Route path="/saved" component={BookmarkList}/>
          <Route exact path="/login" component={Login}/>
          <Route path="/forgot/:hash?" component={ResetPassword}/>
          <Route component={NotFound}/>
        </IonRouterOutlet>
        <IonTabBar
          slot="bottom"
          mode="md"
          className={css`
            position: absolute;
            bottom: 0;
            width: 100%;
            backdrop-filter: blur(10px);
            background: var(--ion-tab-bar-background);
            --background: transparent;
          `}
        >
          <IonTabButton tab="home">
            <IonIcon src="/assets/head.svg"/>
            <Box
              w="100%"
              h="100%"
              pos="absolute"
              onClick={() => navigation.push('/home', 'root')}
              zIndex="10"
            />
          </IonTabButton>
          <IonTabButton tab="search">
            <Icon as={MdSearch} w="24px" h="24px"/>
            <Box
              w="100%"
              h="100%"
              pos="absolute"
              onClick={() => {
                if (location.pathname !== '/home' && location.pathname !== '/hotels' && location.pathname !== '/inspirations') navigation.push('/home', 'root')
                filterDisclosure.onOpen?.()
              }}
              zIndex="10"
            />
          </IonTabButton>
          {(!user.loading && user.isLoggedIn) && (
            <IonTabButton
              className={css`
                padding: 0;
              `}
              tab="upload"
            >
              <FileInputButton
                uppy={uppy}
                id="upload-media-tabbar"
                events={{
                  'file-added': onFileAdded,
                  error: (e) => console.error(e),
                }}
              >
                <Icon as={MdAddCircle} color="var(--ion-color-primary)" w="48px" h="48px"/>
              </FileInputButton>
            </IonTabButton>
          )}
          <IonTabButton tab="placeholder">
            <Icon as={MdOutlineHotel} w="24px" h="24px"/>
            <Box
              w="100%"
              h="100%"
              pos="absolute"
              onClick={() => navigation.push('/hotels', 'root')}
              zIndex="10"
            />
          </IonTabButton>
          <IonTabButton
            tab="more"
            className={css`
                padding: 0;
              `}
          >
            <MoreButton/>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
      <Portal>
        <UploadModal uppy={uppy} files={modalState.files ?? []} onDismiss={handleDismiss} presentingElemet={parentComponentRef.current} isOpen={modalState.uploadModalOpen ?? false}/>
      </Portal>
    </>
  )
}
