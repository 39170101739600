import { Container } from '@chakra-ui/react'
import { IonLabel, IonSegment, IonSegmentButton } from '@ionic/react'
import queryString from 'query-string'
import React from 'react'
import { useHistory, useLocation } from 'react-router'
import { getSortBy } from '../../helper/urlParams'

const SortBar: React.FC = () => {
  const location = useLocation()
  const history = useHistory()
  const parsedQuery = queryString.parse(location.search, { arrayFormat: 'comma' })
  const sortBy = getSortBy(parsedQuery)

  const handleSearch = (value?: string) => () => {
    const parsedQuery = queryString.parse(location.search, { arrayFormat: 'comma' })
    const query = queryString.stringify({ ...parsedQuery, sort: value }, { arrayFormat: 'comma' })

    history.push({
      search: query,
    })
  }

  return (
    <Container maxW="container.md" pt={3}>
      <IonSegment value={sortBy} swipeGesture={false}>
        <IonSegmentButton value="o_creationDate" onClick={handleSearch('o_creationDate')}>
          <IonLabel>Neueste</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value="popularity" onClick={handleSearch('popularity')}>
          <IonLabel>Im Trend</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value="likesCount" onClick={handleSearch('likesCount')}>
          <IonLabel>Beste</IonLabel>
        </IonSegmentButton>
      </IonSegment>
    </Container>
  )
}

export default React.memo(SortBar)
