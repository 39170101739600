import { gql, useMutation } from '@apollo/client'
import { apolloClient } from '../../helper/graphql'
import type { putCommentLikeQuery, putCommentLikeQueryVariables } from './__generated__/putCommentLikeQuery'
import type { putCommentUnlikeQuery, putCommentUnlikeQueryVariables } from './__generated__/putCommentUnlikeQuery'
import type { putHotelLikeQuery, putHotelLikeQueryVariables } from './__generated__/putHotelLikeQuery'
import type { putHotelUnlikeQuery, putHotelUnlikeQueryVariables } from './__generated__/putHotelUnlikeQuery'
import type { putInspirationLikeQuery, putInspirationLikeQueryVariables } from './__generated__/putInspirationLikeQuery'
import type { putInspirationUnlikeQuery, putInspirationUnlikeQueryVariables } from './__generated__/putInspirationUnlikeQuery'
import type { putPostLikeQuery, putPostLikeQueryVariables } from './__generated__/putPostLikeQuery'
import type { putPostUnlikeQuery, putPostUnlikeQueryVariables } from './__generated__/putPostUnlikeQuery'

export const PUT_POST_LIKE = gql`
mutation putPostLikeQuery($postID: Int!, $userAccessToken: String!) {
  createLike(postId: $postID, userAccessToken: $userAccessToken) {
    success,
    exception,
  }
}
`

export const PUT_COMMENT_LIKE = gql`
  mutation putCommentLikeQuery($commentID: Int!, $userAccessToken: String!) {
  createLike(commentId: $commentID, userAccessToken: $userAccessToken) {
    success,
    exception,
  }
}
`

export const PUT_HOTEL_LIKE = gql`
  mutation putHotelLikeQuery($hotelID: Int!, $userAccessToken: String!) {
  createLike(hotelId: $hotelID, userAccessToken: $userAccessToken) {
    success,
    exception,
  }
}
`
export const PUT_INSPIRATION_LIKE = gql`
  mutation putInspirationLikeQuery($inspirationId: Int!, $userAccessToken: String!) {
  createLike(inspirationId: $inspirationId, userAccessToken: $userAccessToken) {
    success,
    exception,
  }
}
`

export const PUT_POST_UNLIKE = gql`
mutation putPostUnlikeQuery($postID: Int!, $userAccessToken: String!) {
  deleteLike(postId: $postID, userAccessToken: $userAccessToken) {
    success
  }
}
`

export const PUT_COMMENT_UNLIKE = gql`
  mutation putCommentUnlikeQuery($commentID: Int!, $userAccessToken: String!) {
  deleteLike(commentId: $commentID, userAccessToken: $userAccessToken) {
    success
  }
}`

export const PUT_HOTEL_UNLIKE = gql`
  mutation putHotelUnlikeQuery($hotelID: Int!, $userAccessToken: String!) {
  deleteLike(hotelId: $hotelID, userAccessToken: $userAccessToken) {
    success
  }
}`

export const PUT_INSPIRATION_UNLIKE = gql`
  mutation putInspirationUnlikeQuery($inspirationId: Int!, $userAccessToken: String!) {
  deleteLike(inspirationId: $inspirationId, userAccessToken: $userAccessToken) {
    success
  }
}`

export function putPostLike (postID: string | number, userAccessToken: string) {
  return apolloClient.query<putPostLikeQuery, putPostLikeQueryVariables>({
    query: PUT_POST_LIKE,
    variables: {
      postID: Number(postID),
      userAccessToken,
    },
  })
}

export function putCommentLike (commentID: string | number, userAccessToken: string) {
  return apolloClient.query<putCommentLikeQuery, putCommentLikeQueryVariables>({
    query: PUT_COMMENT_LIKE,
    variables: {
      commentID: Number(commentID),
      userAccessToken,
    },
  })
}

export function putHotelLike (hotelId: string | number, userAccessToken: string) {
  return apolloClient.query<putHotelLikeQuery, putHotelLikeQueryVariables>({
    query: PUT_HOTEL_LIKE,
    variables: {
      hotelID: Number(hotelId),
      userAccessToken,
    },
  })
}

export function putInspirationLike (inspirationId: string | number, userAccessToken: string) {
  return apolloClient.query<putInspirationLikeQuery, putInspirationLikeQueryVariables>({
    query: PUT_INSPIRATION_LIKE,
    variables: {
      inspirationId: Number(inspirationId),
      userAccessToken,
    },
  })
}

export function putPostUnlike (postID: string | number, userAccessToken: string) {
  return apolloClient.query<putPostUnlikeQuery, putPostUnlikeQueryVariables>({
    query: PUT_POST_UNLIKE,
    variables: {
      postID: Number(postID),
      userAccessToken,
    },
  })
}

export function putCommentUnlike (commentID: string | number, userAccessToken: string) {
  return apolloClient.query<putCommentUnlikeQuery, putCommentUnlikeQueryVariables>({
    query: PUT_COMMENT_UNLIKE,
    variables: {
      commentID: Number(commentID),
      userAccessToken,
    },
  })
}

export function putHotelUnlike (hotelId: string | number, userAccessToken: string) {
  return apolloClient.query<putHotelUnlikeQuery, putHotelUnlikeQueryVariables>({
    query: PUT_HOTEL_UNLIKE,
    variables: {
      hotelID: Number(hotelId),
      userAccessToken,
    },
  })
}

export function putInspirationUnlike (inspirationId: string | number, userAccessToken: string) {
  return apolloClient.query<putInspirationUnlikeQuery, putInspirationUnlikeQueryVariables>({
    query: PUT_INSPIRATION_UNLIKE,
    variables: {
      inspirationId: Number(inspirationId),
      userAccessToken,
    },
  })
}

export function usePostLike () {
  return useMutation<putPostLikeQuery, putPostLikeQueryVariables>(PUT_POST_LIKE)
}

export function useCommentLike () {
  return useMutation<putCommentLikeQuery, putCommentLikeQueryVariables>(PUT_COMMENT_LIKE)
}

export function useHotelLike () {
  return useMutation<putHotelLikeQuery, putHotelLikeQueryVariables>(PUT_HOTEL_LIKE)
}

export function useInspirationLike () {
  return useMutation<putInspirationLikeQuery, putInspirationLikeQueryVariables>(PUT_INSPIRATION_LIKE)
}

export function usePostUnlike () {
  return useMutation<putPostUnlikeQuery, putPostUnlikeQueryVariables>(PUT_COMMENT_UNLIKE)
}

export function useCommentUnlike () {
  return useMutation<putCommentUnlikeQuery, putCommentUnlikeQueryVariables>(PUT_COMMENT_UNLIKE)
}

export function useHotelUnlike () {
  return useMutation<putHotelUnlikeQuery, putHotelUnlikeQueryVariables>(PUT_HOTEL_UNLIKE)
}
export function useInspirationUnlike () {
  return useMutation<putInspirationUnlikeQuery, putInspirationUnlikeQueryVariables>(PUT_INSPIRATION_UNLIKE)
}
