import { gql, useMutation } from '@apollo/client'
import { apolloClient } from '../../helper/graphql'
import type { putCreateUserQuery, putCreateUserQueryVariables } from './__generated__/putCreateUserQuery'

const PUT_CREATE_USER = gql`
  mutation putCreateUserQuery ($pw: String! $pwRepeat: String! $firstName: String! $lastName: String!, $email: String! $emailRepeat: String! $locale: String! $termsAccepted: Boolean!) {
  userRegister(
    user: {
      plainPassword: {
        first: $pw
        second: $pwRepeat
      }
    }
    firstname: $firstName
    lastname: $lastName
    email: {
      first: $email
      second: $emailRepeat
    }
    locale: $locale
    termsAccepted: $termsAccepted
  ) {
    token
    tokenValidUntil
    isActive
    customer {
      user {
        ... on object_CoreShopUser {
          id
        }
      }
    } 
  }
}
`
export function createUser ({ pw, pwRepeat, firstName, lastName, email, emailRepeat, locale, termsAccepted }: putCreateUserQueryVariables) {
  return apolloClient.query<putCreateUserQuery, putCreateUserQueryVariables>({
    query: PUT_CREATE_USER,
    variables: {
      pw,
      pwRepeat,
      firstName,
      lastName,
      email,
      emailRepeat,
      locale,
      termsAccepted,
    },
  })
}

export function useCreateUser () {
  return useMutation<putCreateUserQuery, putCreateUserQueryVariables>(PUT_CREATE_USER)
}
