/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { Box, Flex, Heading, SkeletonText, Tag, TagLabel, Text } from '@chakra-ui/react'
import qs from 'query-string'
import React, { useMemo, useRef } from 'react'
import { useLocation } from 'react-router'
import { useMeasure } from 'react-use'
import type { ICardProps } from '..'
import type { IFilter } from '../../../helper/filterBuilder'
import { calculateTagColor, sortTags } from '../../../helper/tags'
import Interactions from '../../Interactions'

const PostContent: React.FC<ICardProps & { likePost: (value: boolean) => void, commentCount?: number }> = ({ isDetail, post, likePost, commentCount }) => {
  const { search } = useLocation()
  const query = qs.parse(search, { arrayFormat: 'comma' }) as IFilter

  const [textRef, { height: textHeight }] = useMeasure<HTMLParagraphElement>()
  const spanRef = useRef<HTMLSpanElement>(null)

  const sortedTags = useMemo(() => {
    return sortTags(post?.tags)
  }, [post?.tags])

  const filtredSortedTags = useMemo(() => {
    return sortedTags.filter(tag => query.tags?.includes(tag!.id!))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedTags])

  // if this doesn't work, add spanref.current to dependencies
  const isTextCut = useMemo(() => {
    if (!textHeight || !spanRef.current) {
      return false
    }
    return textHeight < spanRef.current.offsetHeight
  }, [textHeight])

  if (!post) {
    return (
      <Box padding="20px">
        <SkeletonText mt="4" noOfLines={4} spacing="4"/>
      </Box>
    )
  }

  return (
    <Box padding="20px">
      <Heading size="md" mb={2}>{post.headline}</Heading>
      <Box _notLast={{ mb: isTextCut ? 2 : 4 }}>
        <Text ref={textRef} whiteSpace="pre-line" noOfLines={post.urlInput?.path || isDetail ? 0 : 3}>
          <span ref={spanRef}>{post.description}</span>
        </Text>
      </Box>
      {!post.urlInput?.path && isTextCut && (
        <Text fontSize="sm" mb={4} opacity={0.5} _hover={{ textDecor: 'underline' }}>
          Mehr lesen
        </Text>
      )}
      {isDetail
        ? (
            Boolean(sortedTags.length) && (
              <Flex css="gap: var(--chakra-space-2)" wrap="wrap" mb={4}>
                {sortedTags.map(tag => (
                  <Tag size="lg" borderRadius="full" colorScheme={calculateTagColor(tag?.tagType?.name ?? '')} key={tag?.id}>
                    <TagLabel>{tag?.name}</TagLabel>
                  </Tag>
                ))}
              </Flex>
            )
          )
        : (
            Boolean(filtredSortedTags.length) && (
              <Flex css="gap: var(--chakra-space-2)" wrap="wrap" mb={4}>
                {filtredSortedTags.map(tag => (
                  <Tag size="lg" borderRadius="full" colorScheme={calculateTagColor(tag?.tagType?.name ?? '')} key={tag?.id}>
                    <TagLabel>{tag?.name}</TagLabel>
                  </Tag>
                ))}
              </Flex>
            )
          )}
      <Interactions isDetail={isDetail} onLiked={likePost} post={post} commentCount={commentCount}/>
    </Box>
  )
}

export default PostContent
