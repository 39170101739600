import { Box, HStack, Spacer } from '@chakra-ui/react'
import React from 'react'
import type { getCommentsQuery_getTribelloCommentListing_edges_node } from '../../../gql/queries/__generated__/getCommentsQuery'
import Like from '../../Interactions/subComponents/Like'
import MentionDisplay from '../../MentionDisplay'

interface IProps {
  comment: getCommentsQuery_getTribelloCommentListing_edges_node
}

const CommentContent: React.FC<IProps> = ({ comment }) => {
  return (
    <>
      <Box fontSize="sm">
        <MentionDisplay mentionString={comment.content ?? ''}/>
      </Box>
      <Spacer mb={3}/>
      <HStack spacing={4} justifyContent="flex-end">
        <Like likeCount={comment.likesCount ?? 0} type="comment" authorId={comment.user?.id ?? 0} id={comment.id ?? ''} onLikeChanged={() => {}}/>
      </HStack>
    </>
  )
}

export default CommentContent
