import { gql, useQuery } from '@apollo/client'
import { apolloClient } from '../../helper/graphql'
import type { getCommentsQuery, getCommentsQueryVariables } from './__generated__/getCommentsQuery'

export const GET_COMMENTS_BY_POST_ID = gql`
  query getCommentsQuery(
    $ids: String
    $fullpaths: String
    $defaultLanguage: String
    $first: Int
    $after: Int
    $sortBy: [String]
    $sortOrder: [String]
    $published: Boolean
    $filter: String
  ) {
    getTribelloCommentListing(
      ids: $ids
      fullpaths: $fullpaths
      defaultLanguage: $defaultLanguage
      first: $first
      after: $after
      sortBy: $sortBy
      sortOrder: $sortOrder
      published: $published
      filter: $filter
    ) {    
      totalCount
      edges {
        node {
          id
          content
          likesCount
          post {
            ... on object_TribelloPost {
              id
            }
          }
          user {
            ...on object_CoreShopUser {
              id
              customer {
                ...on object_CoreShopCustomer {
                  id
                  firstname
                  lastname
                  images {
                    ... on asset {
                      fullpath(thumbnail: "content")
                      mimetype
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

interface IGetCommentsOptions extends Omit<getCommentsQueryVariables, 'filter'> {
  filter?: object
}

function prepareFilter (postId: string | number, filter?: object) {
  return JSON.stringify({
    ...filter,
    post__id: Number(postId),
  })
}

export async function getComments (postId: string | number, options?: IGetCommentsOptions) {
  return apolloClient.query<getCommentsQuery, getCommentsQueryVariables>({
    query: GET_COMMENTS_BY_POST_ID,
    variables: {
      ...options,
      filter: prepareFilter(postId, options?.filter),
    },
  })
}

export function useComments (postId: string | number, options?: IGetCommentsOptions) {
  return useQuery<getCommentsQuery, getCommentsQueryVariables>(GET_COMMENTS_BY_POST_ID, {
    variables: {
      ...options,
      filter: prepareFilter(postId, options?.filter),
    },
    fetchPolicy: 'network-only',
  })
}
