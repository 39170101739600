import { IonContent } from '@ionic/react'
import type { ReactNode } from 'react'
import React from 'react'

const PageContent = React.forwardRef<HTMLIonContentElement, {children: ReactNode, disableScroll?: boolean}>(({ children, disableScroll = false }, ref) => {
  return (
    <IonContent
      ref={ref}
      scrollY={!disableScroll}
    >
      {children}
    </IonContent>
  )
})

export default PageContent
