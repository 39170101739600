import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar } from '@ionic/react'
import { closeCircle } from 'ionicons/icons'
import queryString from 'query-string'
import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useFilterDisclosure } from '../../helper/filterDisclosure'
import { getTagsArray } from '../../helper/urlParams'
import Filters from './subComponents/Filters'

const FilterModal: React.FC = () => {
  const { isOpen, onClose } = useFilterDisclosure()
  const location = useLocation()
  const history = useHistory()
  const parsedQuery = queryString.parse(location.search, { arrayFormat: 'comma' })
  const paramTags = getTagsArray(parsedQuery)
  const [tags, setTags] = useState<number[]>([])

  const handleSearch = () => {
    const parsedQuery = queryString.parse(location.search, { arrayFormat: 'comma' })
    const query = queryString.stringify({ ...parsedQuery, tags }, { arrayFormat: 'comma' })

    history.push({
      search: query,
    })

    onClose?.()
  }

  if (isOpen === undefined || onClose === undefined) return null

  return (
    <IonModal
      mode="ios"
      isOpen={isOpen}
      swipeToClose={true}
      onDidDismiss={onClose}
    >
      <IonHeader translucent>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={() => onClose()}><IonIcon icon={closeCircle}/></IonButton>
          </IonButtons>
          <IonTitle>Filter</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={handleSearch}>
              Suchen
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Filters
          defaultTags={paramTags}
          tags={tags}
          setTags={setTags}
        />
      </IonContent>
    </IonModal>
  )
}

export default FilterModal
