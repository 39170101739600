import { Container } from '@chakra-ui/react'
import { css } from '@emotion/react'
import { IonPage, useIonViewWillEnter } from '@ionic/react'
import React, { useRef } from 'react'
import { useParams } from 'react-router'
import PageContent from '../components/PageContent'
import { useContent } from '../gql/queries/getContent'

const ContentStyle = css`
  h1, h2, h3, h4, h5, h6 {
    margin-top: 16px;
    margin-bottom: 10px;
    font-weight: 500;
    line-height: 1.2;
  }
  h1 {
    margin-top: 20px;
    font-size: 26px;
  }
  h2 {
    margin-top: 18px;
    font-size: 24px;
  }
  h3 {
    font-size: 22px;
  }

  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 18px;
  }

  h6 {
    font-size: 16px;
  }
`

const Content: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null)
  const { path } = useParams<{ path: string }>()
  const { data } = useContent({ fullpath: `/tribello/contents/${path}` })

  useIonViewWillEnter(() => {
    ref.current?.scrollIntoView(true)
  })

  return (
    <IonPage>
      <PageContent>
        <Container ref={ref} css={ContentStyle} maxW="container.md" pt="2em" pb="100px" dangerouslySetInnerHTML={{ __html: data?.getTribelloContentPage?.content ?? '' }}/>
      </PageContent>
    </IonPage>
  )
}

export default Content
