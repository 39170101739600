import { Box, Button, Heading, Image, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import type { getPostQuery_getTribelloPost_advertisement as IPostAdvert } from '../gql/queries/__generated__/getPostQuery'
import { getCompressedImageUrl } from '../helper/asset'

const PostDetailAdvertisement: React.FC<IPostAdvert | null | undefined> = ({ headline, image, text, url }) => {
  return (
    <Box
      w="100%"
      borderRadius="8px"
      bg="var(--ion-card-background)"
      p="4px"
    >
      <Image src={getCompressedImageUrl(image?.fullpath)} alt={headline ?? 'Advertisement Image'} maxH="250px" w="100%" objectFit="cover" borderRadius="8px"/>
      <Box p="12px">
        <VStack spacing={3} alignItems="flex-start">
          <Text fontSize="xs" letterSpacing="-1%" fontWeight="extrabold" opacity={0.55} textTransform="uppercase">Sponsored</Text>
          <Heading size="sm" fontWeight="bold">{headline}</Heading>
          <Text>{text}</Text>
          <Button data-analytics-click-out={`advertisement ${headline ?? 'Advertisement Image'}`} colorScheme="primary" size="sm" alignSelf="flex-end" as="a" target="_blank" href={url?.path ?? '#'}>{url?.text}</Button>
        </VStack>
      </Box>
    </Box>
  )
}

export default PostDetailAdvertisement
