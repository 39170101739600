import { useQuery, gql } from '@apollo/client'
import { apolloClient } from '../../helper/graphql'
import { USER_FRAGMENT } from './getUser'
import type { getPostQuery, getPostQueryVariables } from './__generated__/getPostQuery'

export const POST_FRAGMENT = gql`
  fragment PostFragment on object_TribelloPost {
    id
    description
    popularity
    postType
    likesCount
    commentsCount
    dateTime
    thumbnail {
      ... on asset {
        mimetype
        fullpath(thumbnail: "square")
      }
    }
    urlInput {
      path
    }
    tags {
      ...on object_TribelloTag {
        id
        name
        tagType {
          ...on object_TribelloTagType {
            name
          }
          
        }
      }
    }
    headline
    advertisement {
      ...on object_TribelloAdvertisement {
        id
        headline
        url {
          path
          text
        }
        text
        image {
          ...on asset {
            mimetype
            fullpath(thumbnail: "square")
          }
          dimensions {
            width
            height
          }
        }
      }
    }
    videos {
      ... on asset {
        thumbnail: fullpath(thumbnail: "square")
        fullpath
        mimetype
      }
    }
    images {
      ... on asset {
        fullpath(thumbnail: "square")
        mimetype
      }
    }
    user {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`

export const GET_POST = gql`
  query getPostQuery ($id: Int!, $includeProfileData: Boolean = false) {
    getTribelloPost (id: $id) {
      ...PostFragment
    }
  }

  ${POST_FRAGMENT}
`

export function getPost (id: number | string) {
  return apolloClient.query<getPostQuery, getPostQueryVariables>({
    query: GET_POST,
    variables: {
      id: Number(id),
    },
  })
}

export function usePost (id: number | string) {
  return useQuery<getPostQuery, getPostQueryVariables>(GET_POST, {
    variables: {
      id: Number(id),
    },
  })
}
