import { gql, useQuery } from '@apollo/client'
import { apolloClient } from '../../helper/graphql'
import { POST_FRAGMENT } from './getPost'
import type { getPostsQuery, getPostsQueryVariables } from './__generated__/getPostsQuery'

export const GET_POSTS = gql`
  query getPostsQuery ($ids: String, $fullpaths: String, $defaultLanguage: String, $first: Int, $after: Int, $sortBy: [String], $sortOrder: [String], $filter: String, $published: Boolean, , $includeProfileData: Boolean = false) {
    getTribelloPostListing (ids: $ids, fullpaths: $fullpaths, defaultLanguage: $defaultLanguage, first: $first, after: $after, sortBy: $sortBy, sortOrder: $sortOrder, filter: $filter, published: $published) {
      edges {
        node {
          ...PostFragment
        }
      }
      totalCount
    }
  }

  ${POST_FRAGMENT}
`

export async function getPosts (options?: getPostsQueryVariables) {
  return apolloClient.query<getPostsQuery, getPostsQueryVariables>({
    query: GET_POSTS,
    variables: options,
  })
}

export function usePosts (options?: getPostsQueryVariables) {
  return useQuery<getPostsQuery, getPostsQueryVariables>(GET_POSTS, {
    variables: options,
  })
}
