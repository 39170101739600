import { gql, useQuery } from '@apollo/client'
import { apolloClient } from '../../helper/graphql'
import type { getContentQuery, getContentQueryVariables } from './__generated__/getContentQuery'

export const GET_CONTENT = gql`
  query getContentQuery(
    $id: Int
    $fullpath: String!
    $defaultLanguage: String
  ) {
    getTribelloContentPage(
      id: $id, 
      fullpath: $fullpath, 
      defaultLanguage: $defaultLanguage
    ) {
      content
    }
  }
`

export function getContent (options: getContentQueryVariables) {
  return apolloClient.query<getContentQuery, getContentQueryVariables>({
    query: GET_CONTENT,
    variables: options,
  })
}

export function useContent (options: getContentQueryVariables) {
  return useQuery<getContentQuery, getContentQueryVariables>(GET_CONTENT, {
    variables: options,
  })
}
