import { gql, useQuery } from '@apollo/client'
import { apolloClient } from '../../helper/graphql'
import type { getUserByIdQuery, getUserByIdQueryVariables } from './__generated__/getUserByIdQuery'
import type { getUserByTokenQuery, getUserByTokenQueryVariables } from './__generated__/getUserByTokenQuery'

export const USER_FRAGMENT = gql`
  fragment UserFragment on object_CoreShopUser {
    id
    customer {
      ... on object_CoreShopCustomer {
        id
        firstname
        lastname
        email @include(if: $includeProfileData)
        bio @include(if: $includeProfileData)
        creationDate @include(if: $includeProfileData)
        images {
          ... on asset {
            id
            fullpath(thumbnail: "content")
            mimetype
          }
        }
      }
    }
  }
`

export const GET_USER_BY_TOKEN = gql`
  query getUserByTokenQuery($token: String!, $includeProfileData: Boolean = false) {
    userByToken(userAccessToken: $token) {
      user {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`

export async function getUserByToken (token: string, includeProfileData?: boolean) {
  return apolloClient.query<getUserByTokenQuery, getUserByTokenQueryVariables>({
    query: GET_USER_BY_TOKEN,
    variables: {
      token,
      includeProfileData,
    },
  })
}

export function useUserByToken (token: string, includeProfileData?: boolean, skip?: boolean) {
  return useQuery<getUserByTokenQuery, getUserByTokenQueryVariables>(GET_USER_BY_TOKEN, {
    variables: {
      token,
      includeProfileData,
    },
    skip,
  })
}

export const GET_USER_BY_ID = gql`
  query getUserByIdQuery ($userId: Int!, $includeProfileData: Boolean = false) {
    userById(userId: $userId) {
      user {
        ...UserFragment
      }
    }
  }

  ${USER_FRAGMENT}
`

export async function getUserById (userId: number, includeProfileData?: boolean) {
  return apolloClient.query<getUserByIdQuery, getUserByIdQueryVariables>({
    query: GET_USER_BY_ID,
    variables: {
      userId,
      includeProfileData,
    },
  })
}

export function useUserById (userId: number, includeProfileData?: boolean, skip?: boolean) {
  return useQuery<getUserByIdQuery, getUserByIdQueryVariables>(GET_USER_BY_ID, {
    variables: {
      userId,
      includeProfileData,
    },
    skip,
  })
}
