import type { getPostQuery_getTribelloPost_tags } from '../gql/queries/__generated__/getPostQuery'

export function calculateTagColor (type?: string) {
  switch (type) {
    case 'Regionen':
      return 'blue'
    case 'Aktivitäten':
      return 'yellow'
    case 'Interessen':
      return 'green'
    default:
      return 'red'
  }
}
type ITags = Array<getPostQuery_getTribelloPost_tags | null> | null | undefined

export function sortTags (tags: ITags) {
  return [...tags ?? []].sort((a, b) => {
    if (!a?.tagType?.name) return 1
    if (!b?.tagType?.name) return -1
    return (a.tagType.name).localeCompare(b.tagType.name)
  })
}
