import { Flex, useToast } from '@chakra-ui/react'
import { css } from '@emotion/react'
import React from 'react'
import type { getPostsQuery_getTribelloPostListing_edges_node as IPost } from '../../gql/queries/__generated__/getPostsQuery'
import type { getHotelQuery_getTribelloHotel as IHotel } from '../../gql/queries/__generated__/getHotelQuery'
import { Bookmark } from './subComponents/Bookmark'
import { Comment } from './subComponents/Comment'
import Like from './subComponents/Like'
import Share from './subComponents/Share'
import type { getInspirationListQuery_getInspirationListing_edges_node as IInspiration } from '../../gql/queries/__generated__/getInspirationListQuery'

interface IPayload {
  category: string | undefined
  url: string | undefined
  text: string | undefined | null
  id: string | undefined | null
}

interface InteractionProps{
  post?: IPost
  hotel?: IHotel
  inspiration?: IInspiration
  commentCount?: number
  onLiked: (value: boolean) => void
  isDetail?: boolean
}

const Interactions = ({ isDetail, post, hotel, inspiration, onLiked, commentCount }: InteractionProps): JSX.Element => {
  const toast = useToast()

  const findType = () => {
    if (post) {
      return 'post'
    } else if (hotel) {
      return 'hotel'
    } else {
      return 'inspiration'
    }
  }

  const getSharePayload = (type: 'post' | 'hotel' | 'inspiration') => {
    let payload: IPayload

    switch (type) {
      case 'post':
        payload = {
          category: 'diese Dogstory',
          url: 'posts',
          text: post?.headline,
          id: post?.id,
        }
        break
      case 'hotel':
        payload = {
          category: 'dieses Hotel',
          url: 'hotels',
          text: hotel?.name,
          id: hotel?.id,
        }
        break
      case 'inspiration':
        payload = {
          category: 'diese Inspiration',
          url: 'inspirations',
          text: inspiration?.name,
          id: inspiration?.id,
        }
        break
      default:
        payload = {
          category: 'diese geteilte Info',
          url: 'home',
          text: 'mehr dazu',
          id: '',
        }
    }

    return payload
  }

  async function share () {
    // This is not always truthy as some browsers don'ts support the share API
    // eslint-disable-next-line
    if (navigator.share) {
      await navigator.share({
        title: `Sieh dir ${getSharePayload(findType()).category ?? ''} auf Tribello an!\n`,
        text: `Sieh dir ${getSharePayload(findType()).category ?? ''} auf Tribello an!\n${getSharePayload(findType()).text ?? ''}`,
        url: `${window.location.protocol}//${window.location.host}/${getSharePayload(findType()).url ?? ''}/${getSharePayload(findType()).id ?? ''}`,
      })
    } else {
      toast({
        title: 'Dieser Browser unterstützt das Share-Feature nicht',
        description: 'Bitte nutze einen moderneren Browser, um diese Funktion zu nutzen.',
        status: 'error',
        isClosable: true,
      })
    }
  }

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      css={css`line-height: 1;`}
      onClick={e => e.stopPropagation()}
    >
      <Like onLikeChanged={onLiked} likeCount={post?.likesCount ?? hotel?.likesCount ?? inspiration?.likesCount ?? 0} type={post ? 'post' : hotel ? 'hotel' : 'inspiration'} authorId={post?.user?.id ?? 0} id={post?.id ?? hotel?.id ?? inspiration?.id ?? ''}/>
      {post && (
        <Comment onClick={() => isDetail && document.getElementById('comment-form')?.scrollIntoView({ behavior: 'smooth' })} commentCount={commentCount ?? Number(post.commentsCount)} postId={post.id ?? ''}/>
      )}
      <Bookmark type={findType()} id={getSharePayload(findType()).id ?? ''}/>
      <Share onClick={() => share()}/>
    </Flex>
  )
}

export default Interactions
