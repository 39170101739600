import { gql, useMutation } from '@apollo/client'
import { apolloClient } from '../../helper/graphql'
import type { putLoginQuery, putLoginQueryVariables } from './__generated__/putLoginQuery'

export const PUT_LOGIN = gql`
  mutation putLoginQuery ($email: String!, $password: String!) {
    userLogin (email: $email, password: $password) {
      token
      tokenValidUntil
      isActive
      customer {
        user {
          ... on object_CoreShopUser {
            id
          }
        }
      } 
    }
  }
`

export function putLogin (email: string, password: string) {
  return apolloClient.query<putLoginQuery, putLoginQueryVariables>({
    query: PUT_LOGIN,
    variables: {
      email,
      password,
    },
  })
}

export function useLogin () {
  return useMutation<putLoginQuery, putLoginQueryVariables>(PUT_LOGIN)
}
