/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { Avatar, Center, Container, Flex, Heading, Skeleton, Text } from '@chakra-ui/react'
import { IonHeader, IonIcon, IonPage, useIonViewWillEnter } from '@ionic/react'
import { paw } from 'ionicons/icons'
import React, { useMemo } from 'react'
import { Redirect } from 'react-router'
import { Link } from 'react-router-dom'
import Header from '../components/Header'
import PageContent from '../components/PageContent'
import { useUserBookmarks } from '../gql/queries/getIsBookmarked'
import type { getUserBookmarkedQuery, getUserBookmarkedQuery_getTribelloBookmarkListing_edges } from '../gql/queries/__generated__/getUserBookmarkedQuery'
import { getCompressedImageUrl } from '../helper/asset'
import { useUser } from '../helper/auth'

function bookmarksLoadFilter (data?: getUserBookmarkedQuery) {
  const edges = data?.getTribelloBookmarkListing?.edges
  if (!edges) return []
  return edges.filter((edge): edge is getUserBookmarkedQuery_getTribelloBookmarkListing_edges => Boolean(edge?.node))
}

const BookmarkList: React.FC = () => {
  const user = useUser()
  const { data, loading, refetch } = useUserBookmarks(user.data?.user?.id ?? '0')
  const bookmarks = useMemo(() => bookmarksLoadFilter(data), [data])

  useIonViewWillEnter(() => {
    refetch()
  })

  if (!user.loading && !user.isLoggedIn) return <Redirect to="/login"/>

  return (
    <IonPage>
      <IonHeader>
        <Header title="Merkliste"/>
      </IonHeader>
      <PageContent>
        <Container maxW="container.md" py="2em">
          <Skeleton isLoaded={!loading}>
            {bookmarks.length === 0
              ? (
                <Container key="endReached" p="0" maxW="container.md" paddingBottom="100px">
                  <Center fontSize="8em" color="primary.500">
                    <IonIcon icon={paw}/>
                  </Center>
                  <Heading fontSize="lg" textAlign="center" mb={2}>Du hast bis jetzt noch nichts auf die Merkliste gesetzt.</Heading>
                  <Text textAlign="center">Füge interessante Beiträge per Klick auf das Merklisten-Symbol hinzu.</Text>
                </Container>
                )
              : (
                  bookmarks.map(bookmark => (
                    <Flex
                      as={Link}
                      key={bookmark.node?.id}
                      borderRadius="8px"
                      bg="var(--ion-card-background)"
                      m="16px"
                      p="1em"
                      flexDir="row"
                      alignItems="center"
                      cursor="pointer"
                      to={
                  (bookmark.node?.hotel && `/hotels/${bookmark.node.hotel.id ?? ''}`) ||
                  (bookmark.node?.post && `/posts/${bookmark.node.post.id ?? ''}`) ||
                  // @eslint-disable-next-line
                  (bookmark.node?.inspiration && `/inspirations/${bookmark.node.inspiration.id ?? ''}`) ||
                      '/'
                }
                    >
                      {!bookmark.node?.inspiration && (
                        <Avatar src={bookmark.node?.hotel
                          ? getCompressedImageUrl(bookmark.node.hotel.images?.[0]?.fullpath)
                          : getCompressedImageUrl(bookmark.node?.post?.user?.customer?.images?.[0]?.fullpath)}
                        />
                      )}
                      <Text ml={4}>{bookmark.node?.hotel?.name ? bookmark.node.hotel.name : bookmark.node?.post?.headline ? bookmark.node.post.headline : bookmark.node?.inspiration?.name}</Text>
                    </Flex>
                  ))
                )}
          </Skeleton>
        </Container>
      </PageContent>
    </IonPage>
  )
}

export default BookmarkList
