import { gql, useMutation } from '@apollo/client'
import { apolloClient } from '../../helper/graphql'
import type { putUserResetPasswordQuery, putUserResetPasswordQueryVariables } from './__generated__/putUserResetPasswordQuery'
import type { putUserValidateForgotPasswordTokenQuery, putUserValidateForgotPasswordTokenQueryVariables } from './__generated__/putUserValidateForgotPasswordTokenQuery'

const PUT_USER_RESET_PASSWORD = gql`
  mutation putUserResetPasswordQuery(
    $token: String!
    $password: String!
    $passwordRepeat: String!
  ) {
    userResetPassword(token: $token, password: $password, passwordRepeat: $passwordRepeat) {
      token
      tokenValidUntil
    }
  }
`

export function usePutUserResetPassword () {
  return useMutation<putUserResetPasswordQuery, putUserResetPasswordQueryVariables>(PUT_USER_RESET_PASSWORD, {
    client: apolloClient,
  })
}

export function putUserResetPassword (token: string, password: string, passwordRepeat: string) {
  return apolloClient.mutate<putUserResetPasswordQuery, putUserResetPasswordQueryVariables>({
    mutation: PUT_USER_RESET_PASSWORD,
    variables: {
      token,
      password,
      passwordRepeat,
    },
  })
}

const PUT_USER_VALIDATE_TOKEN = gql`
  mutation putUserValidateForgotPasswordTokenQuery(
    $passwordResetToken: String!
  ) {
    userValidateForgotPasswordToken(passwordResetToken: $passwordResetToken) {
      valid
    }
  }
`

export function usePutUserValidateToken () {
  return useMutation<putUserValidateForgotPasswordTokenQuery, putUserValidateForgotPasswordTokenQueryVariables>(PUT_USER_VALIDATE_TOKEN, {
    client: apolloClient,
  })
}

export function putUserValidateToken (passwordResetToken: string) {
  return apolloClient.mutate<putUserValidateForgotPasswordTokenQuery, putUserValidateForgotPasswordTokenQueryVariables>({
    mutation: PUT_USER_VALIDATE_TOKEN,
    variables: {
      passwordResetToken,
    },
  })
}
