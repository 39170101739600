import { Box } from '@chakra-ui/react'
import { css } from '@emotion/react'
import React, { useCallback } from 'react'
import type { DataFunc, SuggestionDataItem } from 'react-mentions'
import { Mention, MentionsInput as MentionInputLib } from 'react-mentions'
import { getUsers } from '../gql/queries/getUsers'

const MentionInput: React.FC<Partial<React.ComponentProps<typeof MentionInputLib>>> = (props) => {
  const loadUsers: DataFunc = useCallback(async (query, cb) => {
    const users = await getUsers(query)

    if (!users.data.getCoreShopCustomerListing?.edges) return

    const displayUsers: SuggestionDataItem[] = users.data.getCoreShopCustomerListing.edges.map(user => ({
      id: user?.node?.user?.id ?? '',
      display: `${user?.node?.firstname ?? ''} ${user?.node?.lastname ?? ''}`,
    }))

    cb(displayUsers)
  }, [])

  return (
    <Box css={css`
      .mentions__suggestions {
        border: 1px solid var(--ion-color-primary);
      }

      .mentions {
        margin: 1em 0;
      }
      
      .mentions--multiLine .mentions__highlighter {
        border: 1px solid transparent;
        padding: 9px;
        min-height: 63px;
      }

      .mentions--multiLine .mentions__input {
        border: 1px solid silver;
        border-radius: 5px;
        padding: 9px;
        outline: 0;
        box-shadow: none;
        transition: border-color .35s, box-shadow .35s;

        &:focus, &:active {
          border-color: var(--ion-color-primary);
          box-shadow: 0 0 0 4px rgba(var(--ion-color-primary-rgb), .25);
        }
      }

      .mentions__mention {
        position: relative;
        z-index: 1;
        color: var(--ion-color-primary);
        text-decoration: underline;
        pointer-events: none;
      }
    `}
    >
      <MentionInputLib
        {...props}
        className="mentions"
      >
        <Mention
          className="mentions__mention"
          trigger="@"
          data={loadUsers}
          appendSpaceOnAdd={true}
          renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => (
            <Box
              p="8px"
              bg={focused ? 'var(--ion-color-primary)' : 'var(--ion-card-background)'}
              color={focused ? 'var(--ion-color-primary-contrast)' : 'var(--ion-text-color)'}
              key={suggestion.id}
            >
              {highlightedDisplay}
            </Box>
          )}
        />
      </MentionInputLib>
    </Box>
  )
}

export default MentionInput
