import { Box, Text } from '@chakra-ui/layout'
import { Flex, Heading, HStack } from '@chakra-ui/react'
import { useIonRouter } from '@ionic/react'
import dayjs from 'dayjs'
import { useAnimation } from 'framer-motion'
import React, { useCallback, useMemo } from 'react'
import 'swiper/swiper.min.css'
import { getTagsById } from '../../gql/queries/getTags'
import type { getPostQuery_getTribelloPost } from '../../gql/queries/__generated__/getPostQuery'
import PostContent from './subComponents/PostContent'
import PostHeader from './subComponents/PostHeader'
import PostMedia from './subComponents/PostMedia'

export interface ICardProps {
  post?: getPostQuery_getTribelloPost | null
  commentCount?: number
  isDetail?: boolean
  onEdit?: (description: string, headline: string, tags: number[]) => void
}

const Post: React.FC<ICardProps> = ({ post, onEdit, commentCount, isDetail }) => {
  const navigation = useIonRouter()
  const animationControl = useAnimation()
  const [editedPost, setEditedPost] = React.useState<getPostQuery_getTribelloPost | undefined>()

  const likePost = useCallback((value: boolean) => {
    if (!value) return

    animationControl.start({
      opacity: [1, 1, 1, 0],
      scale: [0, 1, 1, 0],
    })
  }, [animationControl])

  const handlePostEdit = useCallback(async (description: string, headline: string, tags: number[]) => {
    const { data: allTags } = await getTagsById(tags.join(','))

    if (post) {
      setEditedPost({
        ...post,
        description,
        headline,
        tags: allTags.getTribelloTagListing?.edges?.map(tag => tag!.node) ?? [],
      })
    }
  }, [post])

  const cardActiveFeedback = useMemo<React.ComponentProps<typeof Box>>(() => ({
    cursor: 'pointer',
    border: '2px solid transparent',
    _hover: {
      border: '2px solid rgba(var(--ion-color-primary-rgb), 0.2)',
    },
    _active: {
      border: '2px solid var(--ion-color-primary)',
    },
    'data-analytics-click-out': post?.urlInput?.path ?? null,
    onClick: () => {
      post?.urlInput?.path
        ? window.open(post.urlInput.path, '_blank')
        : navigation.push(`/posts/${post?.id ?? ''}`)
    },
  }), [navigation, post])

  return (
    <Box
      borderRadius="8px"
      bg="var(--ion-card-background)"
      m="16px"
      transition=".35s"
      {...isDetail ? undefined : cardActiveFeedback}
    >
      {!post?.urlInput?.path
        ? (
          <PostHeader post={post} onEdit={handlePostEdit}/>
          )
        : (
          <Flex justifyContent="space-between" p="1em" align="center">
            <Flex align="center" onClick={e => e.stopPropagation()}>
              <Box>
                <HStack spacing="3">
                  <Flex flexDir="column">
                    <Heading size="sm" overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis">
                      Gesponsert
                    </Heading>
                    <Flex align="center">
                      <Text size="xs" opacity={0.55}>
                        {dayjs(post.dateTime).locale('de').fromNow()}
                      </Text>
                    </Flex>
                  </Flex>
                </HStack>
              </Box>
            </Flex>
          </Flex>
          )}
      <PostMedia post={post} animationControl={animationControl}/>
      <PostContent post={editedPost ?? post} isDetail={isDetail} likePost={likePost} commentCount={commentCount}/>
    </Box>
  )
}

export default Post
