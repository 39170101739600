import { gql, useMutation } from '@apollo/client'
import type { PasswordUpdate } from '../../../__generated__/globalTypes'
import type { putUserUpdatePasswordQuery, putUserUpdatePasswordQueryVariables } from './__generated__/putUserUpdatePasswordQuery'
import { apolloClient } from '../../helper/graphql'

const PUT_USER_UPDATE_PASSWORD = gql`
  mutation putUserUpdatePasswordQuery(
    $userToken: String!
    $form: PasswordUpdate!
  ) {
    userUpdatePassword(userToken: $userToken, form: $form) {
      success
    }
  }
`

export function usePutUserUpdatePassword () {
  return useMutation<putUserUpdatePasswordQuery, putUserUpdatePasswordQueryVariables>(PUT_USER_UPDATE_PASSWORD, {
    client: apolloClient,
  })
}

export function putUserUpdatePassword (userToken: string, form: PasswordUpdate) {
  return apolloClient.mutate<putUserUpdatePasswordQuery, putUserUpdatePasswordQueryVariables>({
    mutation: PUT_USER_UPDATE_PASSWORD,
    variables: {
      userToken,
      form,
    },
  })
}
