import { CloseIcon } from '@chakra-ui/icons'
import { Box, Container, Flex, Spinner, Tag, TagLabel, Text } from '@chakra-ui/react'
import queryString from 'query-string'
import React, { useMemo } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useTagsById } from '../../gql/queries/getTags'
import type { getTribelloTagTagsByType_tagsByTagType_edges_tags as ITag } from '../../gql/queries/__generated__/getTribelloTagTagsByType'
import { calculateTagColor } from '../../helper/tags'
import { getTagsArray } from '../../helper/urlParams'

const TagsBar: React.FC = () => {
  const history = useHistory()
  const location = useLocation()
  const parsedQuery = queryString.parse(location.search, { arrayFormat: 'comma' })
  const tags = getTagsArray(parsedQuery)
  const { data: tagsData, loading } = useTagsById(tags.join(','))

  const selectedTags = useMemo<ITag[] | undefined>(() => tagsData?.getTribelloTagListing?.edges?.map(tag => tag?.node) as ITag[], [tagsData])

  const handleRemoveTag = (id: string) => {
    const parsedQuery = queryString.parse(location.search, { arrayFormat: 'comma' })
    const newTags = Array.isArray(parsedQuery.tags) ? parsedQuery.tags.filter(tag => tag !== id) : parsedQuery.tags === id ? [] : []
    const query = queryString.stringify({ ...parsedQuery, tags: newTags }, { arrayFormat: 'comma' })

    history.push({
      search: query,
    })
  }

  if (tags.length === 0) return null

  return (
    <Container maxW="container.md">
      <Box pt={3} borderBottomWidth={1} borderColor="primary">
        <Text fontSize="sm" mb={1}>Deine Filterauswahl</Text>
        <Text fontSize="xs">Jedes Ergebnis entspricht ALL den gewählten Filtern. Setze weniger Filter, um mehr Ergebnisse zu erhalten.</Text>
        <Box w="full" overflowY="hidden" overflowX="auto">
          <Flex gap={2} paddingY={3}>
            {loading
              ? <Flex alignItems="center" h="32px"><Spinner/></Flex>
              : selectedTags?.map((tag) => (
                <Tag
                  key={tag.id}
                  colorScheme={calculateTagColor(tag.tagType?.name ?? '')}
                  flexGrow={0}
                  flexShrink={0}
                  size="lg"
                  borderRadius="full"
                  onClick={() => handleRemoveTag(tag.id ?? '')}
                >
                  <TagLabel>{tag.name}</TagLabel>
                  <CloseIcon fontSize={10} ml={2}/>
                </Tag>
              ))}
          </Flex>
        </Box>
      </Box>
    </Container>
  )
}

export default React.memo(TagsBar)
