import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { apolloClient } from '../../helper/graphql'
import { HOTEL_FRAGMENT } from './getHotel'
import { INSPIRATION_FRAGMENT } from './getInspirations'
import { POST_FRAGMENT } from './getPost'
import type { getMixedQuery, getMixedQueryVariables } from './__generated__/getMixedQuery'

export const GET_MIXED = gql`
  query getMixedQuery($offset: Int, $perPage: Int, $orderKey: String, $tags: [Int], $tagCondition: String, $includeProfileData: Boolean = false, $filter: String) {
    mixedFeed(offset: $offset, perPage: $perPage, orderKey: $orderKey, tags: $tags, tagCondition: $tagCondition, filter: $filter) {
      totalCount
      edges {
        __typename
        ...HotelFragment
        ...PostFragment
        ...InspirationFragment
        ...on object_TribelloAd {
          html
        }
      }
    }
  }

  ${HOTEL_FRAGMENT}
  ${POST_FRAGMENT}
  ${INSPIRATION_FRAGMENT}
`

export async function getMixed (options?: getMixedQueryVariables) {
  return apolloClient.query<getMixedQuery, getMixedQueryVariables>({
    query: GET_MIXED,
    variables: options,
  })
}

export function useMixed (options?: getMixedQueryVariables) {
  return useQuery<getMixedQuery, getMixedQueryVariables>(GET_MIXED, {
    variables: options,
    fetchPolicy: 'network-only',
  })
}
