import { Container, FormControl, FormErrorMessage, FormHelperText, FormLabel, Input, useToast, VStack } from '@chakra-ui/react'
import { IonButton, IonButtons, IonHeader, IonModal, IonSpinner, IonToolbar } from '@ionic/react'
import React, { useCallback } from 'react'
import { usePutUserUpdatePassword } from '../gql/mutations/putUserUpdatePassword'
import { useUser } from '../helper/auth'

const ChangePasswordModal: React.FC<{open: boolean, setOpen: (open: boolean) => void}> = ({ open, setOpen }) => {
  const [oldPassword, setOldPassword] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [confirmPassword, setConfirmPassword] = React.useState('')
  const [updatePassword, updatePasswordResult] = usePutUserUpdatePassword()
  const toast = useToast()
  const user = useUser()

  // check for min. 8 chars, min. one uppercase, min. one lowercase letter and min. one number
  const validatePassword = useCallback((password: string): boolean => {
    return /[A-Z]/.test(password) &&
    /[a-z]/.test(password) &&
    /[0-9]/.test(password) &&
    /[^A-Za-z0-9]/.test(password) &&
    password.length > 8
  }, [])

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(async (e) => {
    e.preventDefault()

    if (!user.token) return
    if (password !== confirmPassword) {
      toast({
        title: 'Neues Password stimmt nicht überein.',
        status: 'error',
      })
      return
    }

    // return error if password is not valid
    if (!validatePassword(password)) {
      toast({
        title: 'Das Password muss mindestens 8 Zeichen lang sein, einen Großbuchstaben, einen Kleinbuchstaben, ein Sonderzeichen und eine Nummer enthalten.',
        status: 'error',
      })
      return
    }

    const res = await updatePassword({
      variables: {
        userToken: user.token,
        form: {
          password: {
            first: password,
            second: confirmPassword,
          },
          current_password: oldPassword,
        },
      },
    })

    if (!res.data?.userUpdatePassword?.success) {
      return toast({
        title: 'Passwort konnte nicht geändert werden, hast du das alte Passwort richtig eingegeben?',
        status: 'error',
      })
    }

    toast({
      title: 'Passwort erfolgreich geändert.',
      status: 'success',
    })

    setOpen(false)
  }, [user.token, password, confirmPassword, validatePassword, updatePassword, oldPassword, toast, setOpen])

  return (
    <IonModal
      isOpen={open}
      swipeToClose={true}
      onDidDismiss={() => setOpen(false)}
    >
      <form onSubmit={handleSubmit}>
        <IonHeader translucent>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton onClick={() => setOpen(false)}>Abbrechen</IonButton>
            </IonButtons>
            <IonButtons slot="end">
              <IonButton type="submit" color="primary" disabled={updatePasswordResult.loading}>
                {updatePasswordResult.loading ? <IonSpinner/> : 'Speichern'}
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <Container pt={3} h="100%">
          <VStack spacing={3}>
            <FormControl id="oldPassword">
              <FormLabel>Altes Passwort</FormLabel>
              <Input required type="password" value={oldPassword} onChange={e => setOldPassword(e.target.value)}/>
              <FormHelperText>Das alte Passwort eintragen.</FormHelperText>
            </FormControl>
            <FormControl id="password" isInvalid={password !== '' && !validatePassword(password)}>
              <FormLabel>Neues Passwort</FormLabel>
              <Input required type="password" value={password} onChange={e => setPassword(e.target.value)}/>
              <FormHelperText>Das neue Passwort eintragen.</FormHelperText>
              <FormErrorMessage>Das Password muss mindestens 8 Zeichen lang sein, einen Großbuchstaben, einen Kleinbuchstaben, ein Sonderzeichen und eine Nummer enthalten.</FormErrorMessage>
            </FormControl>
            <FormControl id="passwordRepeat" isInvalid={confirmPassword !== '' && confirmPassword !== password}>
              <FormLabel>Password Wiederholen</FormLabel>
              <Input required type="password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)}/>
              <FormHelperText>Neues Passwort wiederholen.</FormHelperText>
              <FormErrorMessage>Passwörter stimmen nicht überein.</FormErrorMessage>
            </FormControl>
          </VStack>
        </Container>
      </form>
    </IonModal>
  )
}

export default ChangePasswordModal
