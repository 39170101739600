import { Center, chakra, Flex } from '@chakra-ui/react'
import { IonIcon } from '@ionic/react'
import { useAnimation } from 'framer-motion'
import { pauseSharp, playSharp } from 'ionicons/icons'
import React, { useCallback, useEffect, useState } from 'react'
import { MotionFlex } from './Motion'

const ChakraIcon = chakra(IonIcon)

interface IProps extends React.VideoHTMLAttributes<HTMLVideoElement> {
  active?: boolean
}

// TODO: Fix Video getting stuck when `active` changes.
const Video: React.FC<IProps> = ({ active, ...videoProps }) => {
  const videoRef = React.useRef<HTMLVideoElement>(null)
  const [isPlaying, setPlaying] = useState(active)
  const control = useAnimation()

  const togglePlay = useCallback((shouldPlay: boolean) => {
    if (!active) {
      videoRef.current?.pause()
      return
    }

    if (shouldPlay) {
      videoRef.current?.play()
    } else {
      videoRef.current?.pause()
    }

    control.start({
      opacity: [0, 1, 0],
      scale: [0, 2, 4],
    })
  }, [active, control])

  useEffect(() => {
    setPlaying(active)
    togglePlay(active ?? false)
  }, [active, togglePlay])

  const handlePlay = useCallback(() => {
    setPlaying(!isPlaying)
    togglePlay(!isPlaying)
  }, [isPlaying, togglePlay, setPlaying])

  return (

    <Center
      pos="relative"
      onClick={active ? handlePlay : undefined}
      pointerEvents={active ? 'all' : 'none'}
      h="100%"
      w="100%"
    >
      <video
        ref={videoRef}
        {...videoProps}
        style={{ objectFit: 'contain', height: '100%' }}
      />
      {active && (
        <Flex flexDir="column" justifyContent="center" alignItems="center" h="100%" w="100%" bgColor="transparent" pos="absolute" top="0" left="0">
          <MotionFlex animate={control} w="100%" justifyContent="space-around" h="100%">
            <Center flexGrow={2} >
              <ChakraIcon color="white" cursor="pointer" size="large" icon={isPlaying ? playSharp : pauseSharp}/>
            </Center>
          </MotionFlex>
        </Flex>
      )}
    </Center>

  )
}

export default Video
