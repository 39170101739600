
import { Button, Center, Container, Heading, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react'
import { IonIcon } from '@ionic/react'
import { search as searchIcon } from 'ionicons/icons'
import queryString from 'query-string'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import type { VirtuosoHandle } from 'react-virtuoso'
import { Virtuoso } from 'react-virtuoso'
import { useMixed } from '../gql/queries/getMixed'
import { useRegistrationPopupDetails } from '../gql/queries/getRegistrationPopupDetails'
import type { getMixedQuery, getMixedQueryVariables, getMixedQuery_mixedFeed_edges } from '../gql/queries/__generated__/getMixedQuery'
import { useUser } from '../helper/auth'
import { getSortBy, getTagsArray } from '../helper/urlParams'
import { EndFeed } from './EndFeed'
import Hotel from './Hotel'
import InspirationElement from './Inspiration'
import Post from './Post'
import PostSkeleton from './Post/subComponents/PostSkeleton'
import TribelloAd from './TribelloAd'

function mixedLoadFilter (data?: getMixedQuery): getMixedQuery_mixedFeed_edges[] {
  const edges = data?.mixedFeed?.edges
  if (!edges) return []
  return edges.filter((edge): edge is getMixedQuery_mixedFeed_edges => Boolean(edge))
}

interface IPostParams {
  type?: 'hotel' | 'post' | 'inspiration'
  perPage?: number
  header?: JSX.Element
  options?: getMixedQueryVariables
}

const PostsNotFoud = (
  <Container maxW="container.md" pt="30px" paddingBottom="76px" width="100%">
    <Center fontSize="8em" color="primary.500">
      <IonIcon icon={searchIcon}/>
    </Center>
    <Heading textAlign="center">Kein Ergebnis</Heading>
    <Text textAlign="center">Leider wurden keine Beiträge mit diesem Suchfilter gefunden. Setze weniger Filter, um ein besseres Ergebnis zu erzielen.</Text>
  </Container>
)

const PostItem = React.memo(({ post }: {post: getMixedQuery_mixedFeed_edges}) => (
  <Container p="0" maxW="container.md">
    {post.__typename === 'object_TribelloPost' && <Post post={post}/>}
    {post.__typename === 'object_TribelloHotel' && <Hotel hotel={post}/>}
    {post.__typename === 'object_Inspiration' && <InspirationElement inspiration={post}/>}
    {post.__typename === 'object_TribelloAd' && <TribelloAd html={post.html?.replace('`', '') ?? ''}/> /* TODO: remove replace as soon as it's fixed in the backend */}
  </Container>
))

const Posts: React.FC<IPostParams> = ({ type, perPage = 6, header, options }) => {
  const { pathname } = useLocation()
  const user = useUser()
  const virtuosoRef = useRef<VirtuosoHandle>(null)
  const loginPopupDetails = useRegistrationPopupDetails()
  const [showLoginPopup, setShowLoginPopup] = useState(false)
  const parsedQuery = queryString.parse(location.search, { arrayFormat: 'comma' })
  const sortBy = getSortBy(parsedQuery)
  const tags = getTagsArray(parsedQuery)

  const { data, fetchMore, loading } = useMixed({ offset: 0, perPage: perPage, tagCondition: 'AND', orderKey: sortBy, filter: type, tags, ...options })
  const posts = useMemo(() => mixedLoadFilter(data), [data])

  useEffect(() => {
    virtuosoRef.current?.scrollToIndex(0)
  }, [sortBy, tags.length, data?.mixedFeed?.totalCount])

  const loadMorePosts = () => {
    fetchMore({
      variables: {
        offset: posts.length,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const previousData = mixedLoadFilter(previousResult)
        const newData = mixedLoadFilter(fetchMoreResult)

        if (!newData.length) return previousResult

        return {
          mixedFeed: {
            edges: [
              ...previousData,
              ...newData,
            ],
            __typename: fetchMoreResult.mixedFeed?.__typename ?? 'MixedFeedResponse',
            totalCount: fetchMoreResult.mixedFeed?.totalCount ?? 0,
          },
        }
      },
    })
  }

  return (
    <>
      {loading
        ? (
          <Container p="0" maxW="container.md">
            <PostSkeleton type={type}/>
            <PostSkeleton type={type}/>
            <PostSkeleton type={type}/>
          </Container>
          )
        : posts.length === 0
          ? PostsNotFoud
          : (
            <Virtuoso
              ref={virtuosoRef}
              onScroll={(e) => setShowLoginPopup((e.target as Element).scrollTop > 20000 && pathname === '/home')}
              data={posts}
              endReached={loadMorePosts}
              overscan={200}
              totalCount={data?.mixedFeed?.totalCount ?? posts.length}
              itemContent={(index, post) => <PostItem post={post}/>}
              components={{
                Header: () => header ?? null,
                Footer: () => <EndFeed isLoading={(data?.mixedFeed?.totalCount ?? 0) > posts.length}/>,
              }}
            />
            )}
      {!user.isLoggedIn && (
        <Modal
          isCentered
          isOpen={pathname === '/home' && showLoginPopup}
          onClose={() => setShowLoginPopup(false)}
          closeOnOverlayClick={false}
        >
          <ModalOverlay
            bg="blackAlpha.300"
            backdropFilter="blur(5px)"
          />
          <ModalContent bg="var(--ion-card-background)">
            <ModalHeader>{loginPopupDetails.data?.getTribelloContentPage?.name}</ModalHeader>
            <ModalBody pb={6} dangerouslySetInnerHTML={{ __html: loginPopupDetails.data?.getTribelloContentPage?.content ?? '' }}/>
            <ModalFooter>
              <RouterLink to="login">
                <Button colorScheme="primary">
                  Jetzt anmelden
                </Button>
              </RouterLink>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  )
}

export default React.memo(Posts)
