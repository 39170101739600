import { gql, useMutation } from '@apollo/client'
import { apolloClient } from '../../helper/graphql'
import type { putUserUpdateQuery, putUserUpdateQueryVariables } from './__generated__/putUserUpdateQuery'

export const PUT_USER_UPDATE = gql`
  mutation putUserUpdateQuery ($userAccessToken: String!, $customer: Customer) {
    userUpdate(userAccessToken: $userAccessToken, customer: $customer) {
      user {
        id
        customer {
          ... on object_CoreShopCustomer {
            firstname
            lastname
          }
        }
      } 
    }
  }
`

export function updateUser (token: string, customer: putUserUpdateQueryVariables['customer']) {
  return apolloClient.mutate<putUserUpdateQuery, putUserUpdateQueryVariables>({
    mutation: PUT_USER_UPDATE,
    variables: {
      userAccessToken: token,
      customer,
    },
  })
}

export function useUpdateUser () {
  return useMutation<putUserUpdateQuery, putUserUpdateQueryVariables>(PUT_USER_UPDATE)
}
