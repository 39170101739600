import { Box, Portal } from '@chakra-ui/react'
import type { Uppy, UppyEventMap } from '@uppy/core'
import { FileInput } from '@uppy/react'
import React, { useCallback, useEffect, useRef } from 'react'
import { useUppyConfig } from '../helper/upload'

interface IFileInputButtonProps {
  events?: Partial<UppyEventMap>
  singleFile?: boolean
  id: string
  uppy?: Uppy
}

const FileInputButton: React.FC<IFileInputButtonProps> = ({ uppy, id, children, events, singleFile }) => {
  const uppyConfigHook = useUppyConfig(id, singleFile)
  const uppyConfig = uppy ?? uppyConfigHook
  const fileInputRef = useRef<HTMLLabelElement & HTMLDivElement>(null)

  const onClick = useCallback(() => {
    fileInputRef.current?.querySelector('input')?.click()
  }, [fileInputRef])

  useEffect(() => {
    if (!events) return
    for (const key in events) {
      if (Object.prototype.hasOwnProperty.call(events, key)) {
        const event = key as keyof UppyEventMap
        const callBack = events[event]
        if (!callBack) continue
        if (event === 'complete') {
          uppyConfig.on(event, () => {
            uppyConfig.reset()
          })
        }
        uppyConfig.on(event, callBack)
      }
    }

    return () => {
      for (const key in events) {
        if (Object.prototype.hasOwnProperty.call(events, key)) {
          const event = key as keyof UppyEventMap
          const callBack = events[event]
          if (!callBack) continue
          uppyConfig.off(event, callBack)
        }
      }
    }
  }, [events, uppyConfig])

  return (
    <>
      {children}
      <Box
        w="100%"
        h="100%"
        pos="absolute"
        onClick={onClick}
        zIndex="10"
      />
      <Portal>
        <Box
          ref={fileInputRef}
          as="label"
          display="none"
        >
          <FileInput id={`${id}`} uppy={uppyConfig} pretty={false}/>
        </Box>
      </Portal>
    </>
  )
}

export default FileInputButton
