import { Avatar, Box, Flex, Heading, HStack } from '@chakra-ui/react'
import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useDeleteComment } from '../../gql/mutations/putDeleteComment'
import type { getCommentsQuery_getTribelloCommentListing_edges_node } from '../../gql/queries/__generated__/getCommentsQuery'
import { getCompressedImageUrl } from '../../helper/asset'
import { getAuthToken, useUser } from '../../helper/auth'
import type { IPopOverState } from '../../types/IPopOverState'
import CommentContent from './subComponents/CommentContent'
import CommentContentEditing from './subComponents/CommentContentEditing'
import CommentPopover from './subComponents/CommentPopover'

interface IProps {
  comment: getCommentsQuery_getTribelloCommentListing_edges_node
}
const Comment: React.FC<IProps> = ({ comment }) => {
  const user = useUser()
  const [popoverState, setShowPopover] = useState<IPopOverState>({ showPopover: false })
  const [editing, setEditing] = useState(false)

  const [deleteComment] = useDeleteComment({ refetchQueries: ['getCommentsQuery'] })

  const handleCommentDelete = async () => {
    const token = getAuthToken()
    if (!comment.id || !token) return
    setShowPopover({ showPopover: false })
    await deleteComment({
      variables: {
        commentID: Number(comment.id),
        userAccessToken: token,
      },
    })

    document.getElementById('comment-form')?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <HStack
      spacing="5"
      alignItems="flex-start"
      borderRadius="8px"
      bg="var(--ion-card-background)"
      p="16px"
    >
      <Avatar src={getCompressedImageUrl(comment.user?.customer?.images?.[0]?.fullpath)}/>
      <Box w="100%">
        <Flex alignItems="center" justifyContent="space-between">
          <Heading as={RouterLink} to={user.isLoggedIn ? `/profile/${comment.user?.id ?? 0}` : '/login'} d="flex" justifyContent="space-between" size="md" _hover={{ textDecor: 'underline' }}>{comment.user?.customer?.firstname} {comment.user?.customer?.lastname}</Heading>
          <CommentPopover comment={comment} deleteComment={handleCommentDelete} setEdit={setEditing} popoverState={popoverState} setShowPopover={setShowPopover}/>
        </Flex>
        {editing
          ? <CommentContentEditing comment={comment} setEditing={setEditing}/>
          : <CommentContent comment={comment}/>}
      </Box>
    </HStack>
  )
}

export default Comment
