import { gql, useMutation } from '@apollo/client'
import { apolloClient } from '../../helper/graphql'
import type { putBioQuery, putBioQueryVariables } from './__generated__/putBioQuery'

const PUT_BIO = gql`
  mutation putBioQuery ($bio: String!, $token: String!) {
    createBioCustomer(bio: $bio, userAccessToken: $token) {
      success
      customer {
        ... on object_CoreShopCustomer {
          bio
        }
      }
    }
  }
`

export function updateBio (bio: string, token: string) {
  return apolloClient.query<putBioQuery, putBioQueryVariables>({
    query: PUT_BIO,
    variables: {
      bio,
      token,
    },
  })
}

export function useUpdateBio () {
  return useMutation<putBioQuery, putBioQueryVariables>(PUT_BIO)
}
