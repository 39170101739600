import { gql, useQuery } from '@apollo/client'
import { apolloClient } from '../../helper/graphql'
import type { getRegistrationDetailsQuery } from './__generated__/getRegistrationDetailsQuery'

const GET_DETAILS = gql`
query getRegistrationDetailsQuery {
  getTribelloContentPage(fullpath:"/tribello/contents/registration") {
    name
    content
  }
}
`

export async function getRegistrationDetails () {
  return apolloClient.query<getRegistrationDetailsQuery>({
    query: GET_DETAILS,
  })
}

export function useRegistrationDetails () {
  return useQuery<getRegistrationDetailsQuery>(GET_DETAILS)
}
