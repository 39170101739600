import { Box } from '@chakra-ui/react'
import React from 'react'
import TagSelect from '../../TagSelect'

const Filters: React.FC<React.ComponentProps<typeof TagSelect>> = (props) => {
  return (
    <Box p={5}>
      <TagSelect {...props}/>
    </Box>
  )
}

export default Filters
