import { gql, useQuery } from '@apollo/client'
import { apolloClient } from '../../helper/graphql'
import type { getIsHotelBookmarkedQuery, getIsHotelBookmarkedQueryVariables } from './__generated__/getIsHotelBookmarkedQuery'
import type { getIsInspirationBookmarkedQuery, getIsInspirationBookmarkedQueryVariables } from './__generated__/getIsInspirationBookmarkedQuery'
import type { getIsPostBookmarkedQuery, getIsPostBookmarkedQueryVariables } from './__generated__/getIsPostBookmarkedQuery'
import type { getUserBookmarkedQuery, getUserBookmarkedQueryVariables } from './__generated__/getUserBookmarkedQuery'

export const GET_IS_POST_BOOKMARKED = gql`
query getIsPostBookmarkedQuery($postID: Int!, $token: String!) {
  bookmarkedByUser (postId: $postID, userAccessToken: $token) {
    bookmarked
  }
}
`
export const GET_IS_HOTEL_BOOKMARKED = gql`
query getIsHotelBookmarkedQuery($hotelId: Int!, $token: String!) {
  bookmarkedByUser (hotelId: $hotelId, userAccessToken: $token) {
    bookmarked
  }
}
`
export const GET_IS_INSPIRATION_BOOKMARKED = gql`
query getIsInspirationBookmarkedQuery($inspirationId: Int!, $token: String!) {
  bookmarkedByUser (inspirationId: $inspirationId, userAccessToken: $token) {
    bookmarked
  }
}
`
export const GET_USER_BOOKMARKED = gql`
query getUserBookmarkedQuery($filter: String!) {
  getTribelloBookmarkListing(filter: $filter) {
    edges{
      node {
        id
        hotel {
          ...on object_TribelloHotel {
            name
            id
            images {
              ...on asset {
                fullpath(thumbnail: "content")
              }
            }
          }
        }
        post {
          ...on object_TribelloPost {
            headline
            id
            user {
              ...on object_CoreShopUser {
                id
                customer {
                  ...on object_CoreShopCustomer {
                    images {
                      ...on asset {
                        fullpath(thumbnail: "content")
                        mimetype
                      }
                    }
                  }
                }
              }
            }
          }
        } 
        inspiration {
          ... on object_Inspiration {
            name
            id
          }
        }  
      }
    }
  }
}
`

export async function getIsPostBookmarked (postID: number | string, token: string) {
  return apolloClient.query<getIsPostBookmarkedQuery, getIsPostBookmarkedQueryVariables>({
    query: GET_IS_POST_BOOKMARKED,
    variables: {
      postID: Number(postID),
      token,
    },
  })
}

export function useIsBookmarked (id: number | string, token: string) {
  return useQuery<getIsPostBookmarkedQuery, getIsPostBookmarkedQueryVariables>(GET_IS_POST_BOOKMARKED, {
    variables: {
      postID: Number(id),
      token,
    },
  })
}

export function useUserBookmarks (userId: string) {
  return useQuery<getUserBookmarkedQuery, getUserBookmarkedQueryVariables>(GET_USER_BOOKMARKED, {
    variables: {
      filter: `{"user__id": {"$like": "%${userId}%"}}`,
    },
  })
}

export async function getIsHotelBookmarked (id: number | string, token: string) {
  return apolloClient.query<getIsHotelBookmarkedQuery, getIsHotelBookmarkedQueryVariables>({
    query: GET_IS_HOTEL_BOOKMARKED,
    variables: {
      hotelId: Number(id),
      token,
    },
  })
}

export async function getIsInspirationBookmarked (id: number | string, token: string) {
  return apolloClient.query<getIsInspirationBookmarkedQuery, getIsInspirationBookmarkedQueryVariables>({
    query: GET_IS_INSPIRATION_BOOKMARKED,
    variables: {
      inspirationId: Number(id),
      token,
    },
  })
}

export async function getIsBookmarked (token: string, type: 'post' | 'hotel' | 'inspiration', id?: number | string) {
  switch (type) {
    case 'post':
      return apolloClient.query<getIsPostBookmarkedQuery, getIsPostBookmarkedQueryVariables>({
        query: GET_IS_POST_BOOKMARKED,
        variables: {
          postID: Number(id),
          token,
        },
      })
    case 'hotel':
      return apolloClient.query<getIsHotelBookmarkedQuery, getIsHotelBookmarkedQueryVariables>({
        query: GET_IS_HOTEL_BOOKMARKED,
        variables: {
          hotelId: Number(id),
          token,
        },
      })
    case 'inspiration':
      return apolloClient.query<getIsInspirationBookmarkedQuery, getIsInspirationBookmarkedQueryVariables>({
        query: GET_IS_INSPIRATION_BOOKMARKED,
        variables: {
          inspirationId: Number(id),
          token,
        },
      })
  }
}
